.main .block-views {
  border-top: 4px solid color(rule);
  margin-bottom: 1.6rem;
  padding-top: 1.6rem;

  // &:first-child {
  //   border-top: 0;
  //   padding-top: 0;
  // }

  .views-row {
    padding-bottom: .6rem;
    border-bottom: 1px solid color(rule);
    margin-bottom: 2rem;

    &:last-child {
      border-bottom: 0;
      margin-bottom: 0;
    }

    .node {
      padding-bottom: 0;
      border-bottom: 0;
    }
  }
}
