
.field,
%field {

  &-content,
  &-name-field-date,
  &-name-field-attachment {
    max-width: 50rem;
    margin-left: auto;
    margin-right: auto;
  }
}
