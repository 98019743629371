// Photocolleciton
//
// Styling for a lead photo-collection
//


.node-faq {
  @include margin-bottom(1);

  h2 {
    @include margin-bottom(.25);

    a[href],
    a:link,
    a:visited {
      border-width: 0;
      color: black;
    }
  }
}
