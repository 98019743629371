
.node-event {
  .field-name-field-location {
    max-width: 50rem;
    margin: 0 auto 2rem;
    color: color(article-date);
  }

  .field-name-field-date + .field-name-field-location {
    margin-top: -2rem;
  }

  .event--ticket-list {
    list-style: none;
    padding: 0;

    li {
      border: 4px solid color('rule');
      padding: 0.8em;
      text-align: center;
      font-size: 120%;
    }
  }

}
