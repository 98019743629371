// Nav menu
//
// A "nav menu" is secondary, hierarchical navigation which can be displayed to the
// side or below the main content.
//
// Markup: nav-menu.twig
//
// Style guide: navigation.nav-menu

.nav-menu,
%nav-menu {

  &__wrapper,
  &__expander {
    background-color: color(nav-bg);
    min-height: 2px;
  }

  &__item {
    list-style-image: image-url('navigation', 'nav-menu/leaf.svg');
    list-style-type: square;

    &.is-expanded,
    &--is-expanded {
      list-style-image: image-url('navigation', 'nav-menu/expanded.svg');
      list-style-type: circle;
    }

    &.is-collapsed,
    &--is-collapsed {
      list-style-image: image-url('navigation', 'nav-menu/collapsed.svg');
      list-style-type: disc;

      @include rtl() {
        list-style-image: image-url('navigation', 'nav-menu/collapsed-rtl.svg');
      }
    }
  }

  &__link {
    &.is-active,
    &--is-active {
      color: color(menu-active);
    }
  }

  &__container {
    position: relative;
    padding: 0;
    width: 100%;
    background-color: color(nav-bg);
  }

  &__show {
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: helvetica, sans-serif;
    font-size: 70%;
    font-weight: bold;
    margin-top: -3px;
    position: absolute;
    top: -47px;
    right: 10px;


    @include zen-respond-to(m) {
      display: none;
      position: absolute;
    }

    &:link,
    &vivisted {
      border-bottom: 0;
    }

    &-label {
      margin-top: -4px;
      clear: both;
    }
  }
}

//
// Drupal selectors.
//

.menu__item {
  @extend %nav-menu__item;

  &.is-expanded {
    @extend %nav-menu__item--is-expanded;
  }

  &.is-collapsed {
    @extend %nav-menu__item--is-collapsed;
  }
}

// The active item in a Drupal menu.
.menu a.active {
  @extend %nav-menu__link--is-active;
}
