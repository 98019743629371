

.join {


  &-membership-header {
    text-align: center;
    font-size: 120%;
    margin-top: 0.8rem;

    @include zen-respond-to(m) {
      font-size: 160%;
    }

  }

  &-membership-options {
    width: 100%;
    margin-bottom: 1.2rem;
    overflow: hidden;
    clear: both;

    .join-membership-option {
      position: relative;
      display: block;
      max-width: 500px;
      width: 75%;
      margin: 0 auto 0;
      min-width: 300px;
      text-align: left;


      @include zen-respond-to(m) {
        max-width: 50%;
        float: left;
      }

    }


    .join-membership-option-header {
      text-align: center;
      margin-top: 0;
      font-weight: bold;
    }

    .join-membership-option-content {
      background-color: color('nav-bg');
      padding: 5%;
      display: block;
      color: black;
      border: 4px solid color('nav-bg');
      box-sizing: border-box;
      &:hover,
      &:active {
        background-color: color('nav-highlight');
        border: 4px solid color('black');
      }
    }

    .join-membership-features {
      list-style: none;
      text-align: left;
      font-weight: bold;
      line-height: 2;
      font-size: 110%;
    }

    .join-membership-buy {
      text-align: center;
      color: black;
      font-weight: bold;
      display: block;
      border: 0;
      max-width: 30%;
      margin: 0 auto 0;
      padding: 0.3rem;

      &-price {
        font-size: 180%;
        clear: both;
        line-height: 1.2;

        &-term {
          display: block;
          font-size: 40%;
          font-weight: 300;
        }
      }

      &-concessionary {
        font-size: 60%;
        color: color('black');
        font-weight: 300;
        border: 0;
        max-width: none;
        display: block;
        margin: 0.6rem auto 1.2rem;
        text-align: center;

        .join-membership-buy-price-term {
          display: inline;
          font-size: 100%;
        }
      }
    }



    p {
      font-size: 90%;
    }

    s {
      color: color('grey');
      font-weight: 300;
    }

  }

  .join-membership-option-free {
    padding: 2.5% 5%;

    .join-membership-option-header {
      font-size: 140%;
      font-weight: 300;
    }

    .join-membership-features {
      font-weight: 300;
      font-size: 90%;
    }

    .join-membership-option-content {
      background-color: color('sub-nav-bg');
      opacity: 0.7;
      border: 4px solid color('sub-nav-bg');

      &:hover,
      &:active {
        background-color: color('sub-nav-highlight');
      }
    }
  }




}
