.main .search-form {

  max-width: none;
  border-bottom: 4px solid color('rule');

  fieldset {
    max-width: 50rem;
    margin: 0 auto;
    border: 0;

    &.container-inline {
      padding: 1.6rem 0;
    }

    &.search-advanced {
      border: 1px solid color('rule');
    }
    &.search-advanced.collapsed {
      border-width: 1px 0 0 0;
    }

  }

  fieldset:last-child {
    margin-bottom: 2.4rem;
  }

  .container-inline {

    legend {
      display: none;
    }

    .fieldset-wrapper {
      display: flex;
      justify-content: center;

      align-items: center;

      .form-item {
        margin: 0 1.6rem 0 0;
        flex-grow: 4;
      }

      .form-text {
        display: inline-block;
        margin-right: 1.6rem;
        font-size: 1rem;
        box-sizing: border-box;
        height: 2.4rem;
      }

      .form-submit {
        display: inline-block;
        margin: 0;
        font-size: 1rem;
        padding: 0 1.6rem;
        box-sizing: border-box;
        height: 2.4rem;

      }
    }
  }

  &:last-child {
    border-bottom-width: 0;
  }

}


.main .search-results {
  max-width: 100%;
  margin: 0;
  padding: 0;

  .search-result {
    border-bottom: 1px solid color('rule');
  }

  .search-result h3,
  .search-result div {
    max-width: 50rem;
    margin: 0 auto;
  }

  .search-snippet-info {
    padding-left: 0;
  }

}
