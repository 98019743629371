// advice
//
// Styling for advice blocks;
//

.main .block-views-advice-block:first-child {
  border-top: 4px solid color('rule');
  padding-top: 1.6rem;
  margin-top: .8rem;
}

.view-advice {
  .view-content {
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;

    .views-row {
      width: 25%;
      min-width: 250px;
      min-height: 200px;
      box-sizing: border-box;
      padding: 0.6rem;
      border-bottom: 0;
      margin: 0;

      .node-advice {
        padding: 0.6rem;
        text-align: center;
        box-sizing: border-box;
        background-color: color('tab-bg');
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        @include padding-bottom(1);

        h1 {
          font-size: 110%;
          line-height: 1.4;
          margin: 0;
          font-weight: bold;
        }

        a:link,
        a:visited {
          color: black;
          border-color: black;
        }

        a:hover,
        a:active {
          background-color: color('sub-nav-bg');
        }

        .field {
          margin: 0;
        }
      }
    }
  }
}
