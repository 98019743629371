.nav-menu {

  &--functions {
    overflow: hidden;
    padding: 0.8rem 0 0.8rem 0;


    @include zen-respond-to(m) {
      clear: both;
      padding-top: 0;
      overflow: hidden;
      position: absolute;
      right: 0;
      top: -142px;

      .block {
        float: right;
        clear: none;
        margin-top: 1rem;
      }
    }
  }
}
