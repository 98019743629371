/*! typey | GPLv2 License | https://github.com/jptaranto/typey */
* {
  box-sizing: border-box;
}

html {
  font-size: 93.75%;
  line-height: 1.6em;
  font-family: "Neue Helvetica eText W01", Helvetica, Arial, "Nimbus Sans L", sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  min-height: 100%;
}

@media print {
  html {
    font-size: 12pt;
  }
}

body {
  margin: 0;
  padding: 0;
  color: #000;
  background-color: #fff;
  min-width: 320px;
  font-weight: 200;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

progress {
  vertical-align: baseline;
}

template,
[hidden] {
  display: none;
}

a {
  color: #39c6f4;
  -webkit-text-decoration-skip: objects;
}

a:link {
  padding-bottom: 3px;
  text-decoration: none;
  border-bottom: 1px dotted #39c6f4;
}

a:visited {
  color: #0a91bd;
  border-bottom: 1px dashed #0a91bd;
}

a:hover,
a:focus {
  color: #84c556;
  border-color: #84c556;
}

a:active {
  color: #84c556;
}

a:active:not(.button) {
  background-color: transparent;
}

a:active,
a:hover {
  border-bottom: 1px dashed #84c556;
  outline-width: 0;
}

@media print {
  a[href]::after {
    content: " (" attr(href) ")";
    font-weight: normal;
    font-size: 15px;
    text-decoration: none;
  }
  a[href^='javascript:']::after,
  a[href^='#']::after {
    content: '';
  }
}

h1, .header__site-name, h2, h3, h4, h5, h6 {
  font-weight: 200;
}

h1,
.header__site-name {
  font-size: 2.13333rem;
  line-height: 2.4rem;
  margin-top: 0;
  margin-bottom: 0.8rem;
}

h2 {
  font-size: 1.6rem;
  line-height: 2.24rem;
  margin-top: 1.6rem;
  margin-bottom: 0.64rem;
}

h3 {
  font-size: 1.33333rem;
  line-height: 2.24rem;
  margin-top: 1.6rem;
  margin-bottom: 0.32rem;
}

h4 {
  font-size: 1rem;
  line-height: 1.92rem;
  margin-top: 1.6rem;
  margin-bottom: 0.16rem;
  font-weight: bold;
}

h5 {
  font-size: 1rem;
  line-height: 1.92rem;
  margin-top: 1.6rem;
  margin-bottom: 0.16rem;
  font-style: italic;
}

h6 {
  font-size: 1rem;
  line-height: 1.92rem;
  margin-top: 1.6rem;
  margin-bottom: 0.16rem;
}

abbr[title] {
  border-bottom: 0;
  text-decoration: underline;
  text-decoration: underline dotted;
}

@media print {
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
}

b,
strong {
  font-weight: inherit;
}

strong,
b {
  font-weight: bolder;
}

pre,
code,
kbd,
samp,
var {
  font-family: Menlo, "DejaVu Sans Mono", "Ubuntu Mono", Courier, "Courier New", monospace, sans-serif;
  font-size: 1em;
}

dfn {
  font-style: italic;
}

mark {
  background-color: #f8c746;
  color: #000;
}

small {
  font-size: 0.93333rem;
}

sub,
sup {
  font-size: 0.66667rem;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

.divider,
hr {
  margin: 1.6rem 0;
  border: 0;
  border-top: 1px solid #cbeae3;
}

.divider > :first-child, hr > :first-child {
  margin-top: 1.6rem;
}

blockquote {
  margin: 1.6rem 2rem;
}

dl,
menu,
ol,
ul {
  margin: 1.6rem 0;
}

ol,
ul {
  padding: 0 0 0 30px;
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
  padding: 0 0 0 15px;
}

dd {
  margin: 0 0 0 30px;
}

[dir="rtl"] dd {
  margin: 0 30px 0 0;
}

[dir="rtl"] menu, [dir="rtl"]
ol, [dir="rtl"]
ul {
  padding: 0 30px 0 0;
}

figure {
  margin: 0 0 1.6rem 0;
}

fieldset {
  margin: 0 0 1.6rem 0;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

p,
pre {
  margin: 0 0 1.6rem 0;
}

pre {
  max-width: 100%;
  overflow-y: auto;
  font-size: 90%;
}

@media (min-width: 777px) {
  pre {
    max-width: none;
    overflow: none;
  }
}

img {
  border-style: none;
}

img,
svg {
  max-width: 100%;
  height: auto;
}

svg:not(:root) {
  overflow: hidden;
}

button,
input,
optgroup,
select,
textarea {
  box-sizing: border-box;
  max-width: 100%;
  min-height: 2.4rem;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

input {
  overflow: visible;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type='search']::-webkit-search-cancel-button, [type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: .54;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

.button,
button,
html [type='button'],
[type='reset'],
[type='submit'] {
  -moz-appearance: button;
  -webkit-appearance: button;
}

.button,
button,
[type='button'],
[type='reset'],
[type='submit'] {
  margin-bottom: 1.6rem;
  box-sizing: border-box;
  border: 1px solid white;
  border-color: white #94d4c6 #94d4c6 white;
  display: inline-block;
  padding: 5px 30px;
  height: 2.4rem;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  background-color: #cbeae3;
}

.button::-moz-focus-inner,
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

.button:-moz-focusring,
button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}

.button:hover, .button:focus, .button:active,
button:hover,
button:focus,
button:active,
[type='button']:hover,
[type='button']:focus,
[type='button']:active,
[type='reset']:hover,
[type='reset']:focus,
[type='reset']:active,
[type='submit']:hover,
[type='submit']:focus,
[type='submit']:active {
  text-decoration: none;
  border: 1px solid #94d4c6;
  border-color: #94d4c6 white white #94d4c6;
  background-color: #a6dcd0;
}

[disabled].button,
button[disabled],
[disabled][type='button'],
[disabled][type='reset'],
[disabled][type='submit'] {
  cursor: default;
  border: 1px solid #555;
  color: #666666;
}

[disabled].button:hover,
button[disabled]:hover,
[disabled][type='button']:hover,
[disabled][type='reset']:hover,
[disabled][type='submit']:hover, [disabled].button:focus,
button[disabled]:focus,
[disabled][type='button']:focus,
[disabled][type='reset']:focus,
[disabled][type='submit']:focus, [disabled].button:active,
button[disabled]:active,
[disabled][type='button']:active,
[disabled][type='reset']:active,
[disabled][type='submit']:active {
  background-color: white;
}

[type='text'],
[type='search'],
[type='tel'],
[type='url'],
[type='email'],
[type='password'],
[type='date'],
[type='time'],
[type='number'], textarea {
  border: 1px solid #000;
  font-size: 80%;
  padding: 1px 6px;
  width: 100%;
  font-family: courier, monospace;
}

[type='checkbox'],
[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}

fieldset {
  padding: 0.56rem 1rem 1.04rem;
  border: 1px solid #cccccc;
  margin: 0 0 1.6rem 0;
}

legend {
  box-sizing: border-box;
  display: block;
  max-width: 100%;
  white-space: normal;
  color: inherit;
  margin-left: -5px;
  padding: 0 5px;
}

legend a:link,
legend a:visited {
  border-bottom: none;
  color: #000;
}

label {
  display: block;
  margin-bottom: 0.1rem;
}

optgroup {
  font-weight: bold;
}

textarea {
  overflow: auto;
}

table {
  margin: 1.6rem 0;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

td,
th {
  padding: 0;
}

.layout-page-wrapper {
  min-height: calc(100vh - 240px);
}

.layout-3col {
  margin-left: -5px;
  margin-right: -5px;
  padding-left: 0;
  padding-right: 0;
}

.layout-3col:before {
  content: "";
  display: table;
}

.layout-3col:after {
  content: "";
  display: table;
  clear: both;
}

.layout-3col__full, .layout-3col__left-content, .layout-3col__right-content, .layout-3col__left-sidebar, .layout-3col__right-sidebar, .layout-3col__first-left-sidebar, .layout-3col__second-left-sidebar, .layout-3col__col-1, .layout-3col__col-2, .layout-3col__col-3, .layout-3col__col-4, .layout-3col__col-x {
  clear: both;
  padding-left: 5px;
  padding-right: 5px;
  float: left;
  width: 100%;
  margin-left: 0%;
  margin-right: -100%;
}

[dir="rtl"] .layout-3col__full, [dir="rtl"] .layout-3col__left-content, [dir="rtl"] .layout-3col__right-content, [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__right-sidebar, [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__second-left-sidebar, [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-4, [dir="rtl"] .layout-3col__col-x {
  float: right;
  margin-right: 0%;
  margin-left: -100%;
}

@media (min-width: 777px) {
  .layout-3col {
    margin-left: -10px;
    margin-right: -10px;
    padding-left: 0;
    padding-right: 0;
  }
  .layout-3col:before {
    content: "";
    display: table;
  }
  .layout-3col:after {
    content: "";
    display: table;
    clear: both;
  }
  .layout-3col__full, .layout-3col__left-content, .layout-3col__right-content, .layout-3col__left-sidebar, .layout-3col__right-sidebar {
    float: left;
    width: 100%;
    margin-left: 0%;
    margin-right: -100%;
    padding-left: 10px;
    padding-right: 10px;
  }
  [dir="rtl"] .layout-3col__full, [dir="rtl"] .layout-3col__left-content, [dir="rtl"] .layout-3col__right-content, [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__right-sidebar {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  .layout-3col__first-left-sidebar, .layout-3col__col-1, .layout-3col__col-3, .layout-3col__col-x:nth-child(2n + 1) {
    float: left;
    width: 50%;
    margin-left: 0%;
    margin-right: -100%;
    padding-left: 10px;
    padding-right: 10px;
  }
  [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-x:nth-child(2n + 1) {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  .layout-3col__second-left-sidebar, .layout-3col__col-2, .layout-3col__col-4, .layout-3col__col-x:nth-child(2n) {
    clear: none;
    float: left;
    width: 50%;
    margin-left: 50%;
    margin-right: -100%;
    padding-left: 10px;
    padding-right: 10px;
  }
  [dir="rtl"] .layout-3col__second-left-sidebar, [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-4, [dir="rtl"] .layout-3col__col-x:nth-child(2n) {
    float: right;
    margin-right: 50%;
    margin-left: -100%;
  }
}

@media (min-width: 999px) {
  .layout-3col__full {
    float: left;
    width: 100%;
    margin-left: 0%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__full {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  .layout-3col__left-content {
    float: left;
    width: 66.66667%;
    margin-left: 0%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__left-content {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  .layout-3col__right-content {
    float: left;
    width: 66.66667%;
    margin-left: 33.33333%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__right-content {
    float: right;
    margin-right: 33.33333%;
    margin-left: -100%;
  }
  .layout-3col__left-sidebar, .layout-3col__first-left-sidebar, .layout-3col__second-left-sidebar {
    clear: right;
    float: right;
    width: 33.33333%;
    margin-right: 66.66667%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__second-left-sidebar {
    clear: left;
  }
  [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__second-left-sidebar {
    float: left;
    margin-left: 66.66667%;
    margin-right: -100%;
  }
  .layout-3col__right-sidebar {
    clear: right;
    float: right;
    width: 33.33333%;
    margin-right: 0%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__right-sidebar {
    clear: left;
  }
  [dir="rtl"] .layout-3col__right-sidebar {
    float: left;
    margin-left: 0%;
    margin-right: -100%;
  }
  .layout-3col__col-1, .layout-3col__col-x:nth-child(3n+1) {
    clear: both;
    float: left;
    width: 33.33333%;
    margin-left: 0%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-x:nth-child(3n+1) {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  .layout-3col__col-2, .layout-3col__col-x:nth-child(3n+2) {
    clear: none;
    float: left;
    width: 33.33333%;
    margin-left: 33.33333%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-x:nth-child(3n+2) {
    float: right;
    margin-right: 33.33333%;
    margin-left: -100%;
  }
  .layout-3col__col-3, .layout-3col__col-x:nth-child(3n) {
    clear: none;
    float: left;
    width: 33.33333%;
    margin-left: 66.66667%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-x:nth-child(3n) {
    float: right;
    margin-right: 66.66667%;
    margin-left: -100%;
  }
  .layout-3col__col-4 {
    display: none;
  }
}

.layout-3col__grid-item-container {
  padding-left: 0;
  padding-right: 0;
}

.layout-3col__grid-item-container:before {
  content: "";
  display: table;
}

.layout-3col__grid-item-container:after {
  content: "";
  display: table;
  clear: both;
}

.layout-center {
  padding-left: 5px;
  padding-right: 5px;
  margin: 0 auto;
  max-width: 1160px;
}

@media (min-width: 777px) {
  .layout-center {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.layout-center--shared-grid,
.layout-center.layout-3col {
  padding-left: 0;
  padding-right: 0;
}

.layout-swap {
  position: relative;
}

@media (min-width: 555px) {
  .layout-swap {
    padding-top: 20px;
  }
}

@media (min-width: 555px) {
  .layout-swap__top {
    position: absolute;
    top: 0;
    height: 48px;
    width: 100%;
  }
}

.main .node {
  padding-bottom: .8rem;
}

.main .node header a:link,
.main .node header a:visited {
  color: #000;
  border-bottom: 0;
}

.main .node .field-type-datetime {
  max-width: 50rem;
  margin: 0 auto 2rem;
  color: #999999;
}

.main .node .field-name-field-lead-image {
  padding: 1.6rem;
  margin-bottom: 1.6rem;
  text-align: center;
  background-color: #000;
  margin-left: -10px;
  margin-right: -10px;
  overflow: hidden;
}

@media (min-width: 777px) {
  .main .node .field-name-field-lead-image {
    margin-left: 0;
    margin-right: 0;
  }
}

.main .node .field-name-field-lead-image .field-item {
  float: none;
  width: auto;
  margin: 0 auto;
  clear: both;
}

.main .node .field-name-field-lead-image figure,
.main .node .field-name-field-lead-image img {
  padding: 0;
  margin: 0;
}

.main .node .field-name-field-lead-image figcaption {
  text-align: right;
  color: #cccccc;
  font-size: 80%;
  line-height: 1.3;
  margin: 0 auto;
}

.main .node:last-child {
  border-bottom: 0;
  margin-bottom: 0;
}

.main .node-teaser h2 {
  margin-top: 0;
}

.main .block-views {
  border-top: 4px solid #cbeae3;
  margin-bottom: 1.6rem;
  padding-top: 1.6rem;
}

.main .block-views .views-row {
  padding-bottom: .6rem;
  border-bottom: 1px solid #cbeae3;
  margin-bottom: 2rem;
}

.main .block-views .views-row:last-child {
  border-bottom: 0;
  margin-bottom: 0;
}

.main .block-views .views-row .node {
  padding-bottom: 0;
  border-bottom: 0;
}

.views-field {
  max-width: 50rem;
  margin-left: auto;
  margin-right: auto;
}

.main .block-views-advice-block:first-child {
  border-top: 4px solid #cbeae3;
  padding-top: 1.6rem;
  margin-top: .8rem;
}

.view-advice .view-content {
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
}

.view-advice .view-content .views-row {
  width: 25%;
  min-width: 250px;
  min-height: 200px;
  box-sizing: border-box;
  padding: 0.6rem;
  border-bottom: 0;
  margin: 0;
}

.view-advice .view-content .views-row .node-advice {
  padding: 0.6rem;
  text-align: center;
  box-sizing: border-box;
  background-color: #d9f0eb;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 1.6rem;
}

.view-advice .view-content .views-row .node-advice h1 {
  font-size: 110%;
  line-height: 1.4;
  margin: 0;
  font-weight: bold;
}

.view-advice .view-content .views-row .node-advice a:link,
.view-advice .view-content .views-row .node-advice a:visited {
  color: black;
  border-color: black;
}

.view-advice .view-content .views-row .node-advice a:hover,
.view-advice .view-content .views-row .node-advice a:active {
  background-color: #aeded3;
}

.view-advice .view-content .views-row .node-advice .field {
  margin: 0;
}

.box {
  margin-bottom: 1.6rem;
  border: 5px solid #cccccc;
  padding: 1em;
}

.box__title {
  margin: 0;
}

.box:focus, .box:hover, .box.is-focus, .box--is-focus {
  border-color: #000;
}

.box--highlight {
  border-color: #39c6f4;
}

body.cke_editable_themed {
  padding: 0.4rem 0.6rem;
}

.clearfix::before,
.footer::before,
.header::before,
.tabs::before,
.action-links::before {
  content: '';
  display: table;
}

.clearfix::after,
.footer::after,
.header::after,
.tabs::after,
.action-links::after {
  content: '';
  display: table;
  clear: both;
}

.main .block-views-recent-collections-block-list-wrapper,
.main .block-views-members-collections-index-block-list-wrapper,
.main .view-collections-list-wrapper,
.main .collections-list-wrapper {
  max-width: none;
  margin: 0 -.5rem 1.6rem;
}

.main .block-views-recent-collections-block .views-row,
.main .block-views-members-collections-index-block .views-row,
.main .view-collections .views-row,
.main .collections .views-row {
  border-bottom: 1px solid #cbeae3;
}

.main .block-views-recent-collections-block .views-row:last-child,
.main .block-views-members-collections-index-block .views-row:last-child,
.main .view-collections .views-row:last-child,
.main .collections .views-row:last-child {
  border-bottom: 1px solid #cbeae3;
  margin-bottom: 2rem;
}

.main .block-views-recent-collections-block-list,
.main .block-views-members-collections-index-block-list,
.main .view-collections-list,
.main .collections-list {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  max-width: none;
  flex-wrap: wrap;
  max-width: none;
}

.main .block-views-recent-collections-block-list .views-row,
.main .block-views-members-collections-index-block-list .views-row,
.main .view-collections-list .views-row,
.main .collections-list .views-row {
  width: 25%;
  position: relative;
}

.main .block-views-recent-collections-block-list-item,
.main .block-views-members-collections-index-block-list-item,
.main .view-collections-list-item,
.main .collections-list-item {
  min-width: 140px;
  width: 320px;
  max-width: 22%;
  margin: .8rem;
  border-bottom: 1px solid #cbeae3;
}

.main .block-views-recent-collections-block-list-item:last-child,
.main .block-views-members-collections-index-block-list-item:last-child,
.main .view-collections-list-item:last-child,
.main .collections-list-item:last-child {
  border-bottom: 1px solid #cbeae3;
  margin-bottom: 2rem;
}

.main .block-views-recent-collections-block-list-item .field-content,
.main .block-views-members-collections-index-block-list-item .field-content,
.main .view-collections-list-item .field-content,
.main .collections-list-item .field-content {
  line-height: 1.3;
}

.main .block-views-recent-collections-block-list-item .views-field-field-image-main,
.main .block-views-members-collections-index-block-list-item .views-field-field-image-main,
.main .view-collections-list-item .views-field-field-image-main,
.main .collections-list-item .views-field-field-image-main {
  position: relative;
  overflow: hidden;
  margin-bottom: .25rem;
}

.main .block-views-recent-collections-block-list-item .views-field-field-image-main .field-content,
.main .block-views-members-collections-index-block-list-item .views-field-field-image-main .field-content,
.main .view-collections-list-item .views-field-field-image-main .field-content,
.main .collections-list-item .views-field-field-image-main .field-content {
  position: relative;
  overflow: visible;
  padding-bottom: 66%;
}

.main .block-views-recent-collections-block-list-item .views-field-field-image-main figure,
.main .block-views-members-collections-index-block-list-item .views-field-field-image-main figure,
.main .view-collections-list-item .views-field-field-image-main figure,
.main .collections-list-item .views-field-field-image-main figure {
  margin: 0;
}

.main .block-views-recent-collections-block-list-item .views-field-field-image-main a,
.main .block-views-members-collections-index-block-list-item .views-field-field-image-main a,
.main .view-collections-list-item .views-field-field-image-main a,
.main .collections-list-item .views-field-field-image-main a {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  padding: 0;
  z-index: 1;
}

.main .block-views-recent-collections-block-list-item .views-field-field-image-main a img,
.main .block-views-members-collections-index-block-list-item .views-field-field-image-main a img,
.main .view-collections-list-item .views-field-field-image-main a img,
.main .collections-list-item .views-field-field-image-main a img {
  position: relative;
  display: block;
  max-height: 100%;
  height: 100%;
  width: auto;
}

.main .block-views-recent-collections-block-list-item .views-field-field-image-main figcaption,
.main .block-views-members-collections-index-block-list-item .views-field-field-image-main figcaption,
.main .view-collections-list-item .views-field-field-image-main figcaption,
.main .collections-list-item .views-field-field-image-main figcaption {
  display: none;
}

.main .block-views-recent-collections-block-list-item img,
.main .block-views-members-collections-index-block-list-item img,
.main .view-collections-list-item img,
.main .collections-list-item img {
  margin: 0;
}

.main .block-views-recent-collections-block-list-item a:link,
.main .block-views-recent-collections-block-list-item a[href],
.main .block-views-recent-collections-block-list-item a:visited,
.main .block-views-members-collections-index-block-list-item a:link,
.main .block-views-members-collections-index-block-list-item a[href],
.main .block-views-members-collections-index-block-list-item a:visited,
.main .view-collections-list-item a:link,
.main .view-collections-list-item a[href],
.main .view-collections-list-item a:visited,
.main .collections-list-item a:link,
.main .collections-list-item a[href],
.main .collections-list-item a:visited {
  border: 0;
  padding: 0;
}

.main .form-field-type-image .image-preview {
  float: none;
}

.main .form-field-type-image .image-preview img {
  margin-bottom: 0;
}

.main .form-field-type-image .image-widget-data {
  float: none;
}

.main .form-field-type-image .image-widget-data .filter-wrapper {
  display: none;
}

.main .form-field-type-image fieldset {
  padding: 0;
  border-left: 0;
  border-right: 0;
}

.main .form-field-type-image table {
  width: 100%;
  border: 0;
}

.main .form-field-type-image table tbody {
  border-top: 0;
}

.main .form-field-type-image table th {
  font-weight: 300;
  padding-left: 0;
}

.main .form-field-type-image table tr {
  padding: 0;
}

.main .form-field-type-image table td {
  vertical-align: bottom;
  padding: 0.6rem;
}

.main .form-field-type-image table td .image-widget-data {
  padding-left: 23px;
}

.main .form-field-type-image table .tabledrag-handle {
  border: 0;
}

.main .node-33 {
  padding: 0;
}

.map,
.main .map {
  margin: -20px 0 -20px;
  width: 100%;
  min-height: 70vh;
  max-height: 100vh;
  height: 80%;
}

.comment__section,
.comments {
  margin: 1.6rem 0;
}

.comment__title {
  margin: 0;
}

.comment__permalink {
  text-transform: uppercase;
  font-size: 75%;
}

.comment--preview,
.comment-preview {
  background-color: white;
}

.comment--nested,
.indented {
  margin-left: 30px;
}

[dir="rtl"] .comment--nested,
[dir="rtl"] .indented {
  margin-left: 0;
  margin-right: 30px;
}

.node-event .field-name-field-location {
  max-width: 50rem;
  margin: 0 auto 2rem;
  color: #999999;
}

.node-event .field-name-field-date + .field-name-field-location {
  margin-top: -2rem;
}

.node-event .event--ticket-list {
  list-style: none;
  padding: 0;
}

.node-event .event--ticket-list li {
  border: 4px solid #cbeae3;
  padding: 0.8em;
  text-align: center;
  font-size: 120%;
}

.node-faq {
  margin-bottom: 1.6rem;
}

.node-faq h2 {
  margin-bottom: 0.4rem;
}

.node-faq h2 a[href],
.node-faq h2 a:link,
.node-faq h2 a:visited {
  border-width: 0;
  color: black;
}

.field-content, .field-name-field-date, .field-name-field-attachment {
  max-width: 50rem;
  margin-left: auto;
  margin-right: auto;
}

.footer {
  background-color: #d9f0eb;
  color: #3d3d3d;
  padding: 20px 0;
}

.footer h1 {
  font-size: 125%;
  margin-bottom: 2px;
  line-height: 1.3;
  text-align: left;
  color: #3d3d3d;
}

@media (min-width: 777px) {
  .footer h1 {
    width: auto;
  }
}

.footer p {
  font-size: 90%;
}

.footer a[href],
.footer a:link,
.footer a:visited {
  color: #3d3d3d;
  border-bottom: 1px dotted #3d3d3d;
}

.footer a[href] .active,
.footer a:link .active,
.footer a:visited .active {
  font-weight: bold;
}

.footer a:hover,
.footer a:active {
  border-bottom: 1px solid #3d3d3d;
  color: black;
}

.footer h4 {
  font-size: 140%;
  color: inherit;
}

.footer ul.menu {
  padding: 0;
  margin-top: 0;
  list-style: none;
  font-size: 90%;
  margin-right: -.5rem;
  columns: 2;
  column-gap: 1rem;
  color: #3d3d3d;
}

.footer ul.menu a[href],
.footer ul.menu a:link,
.footer ul.menu a:visited {
  border-bottom: 1px solid #d9f0eb;
  color: #3d3d3d;
}

.footer ul.menu a[href] .active,
.footer ul.menu a:link .active,
.footer ul.menu a:visited .active {
  font-weight: bold;
}

.footer ul.menu a:hover,
.footer ul.menu a:active {
  border-bottom: 1px solid #3d3d3d;
  color: black;
}

.footer ul.menu li {
  list-style: none;
  padding-right: 1rem;
  white-space: nowrap;
  position: relative;
}

.footer ul.menu li .is-active-trail,
.footer ul.menu li .active {
  font-weight: bold;
}

.footer ul.menu ul {
  padding-left: 1.6rem;
  font-size: 100%;
  columns: 1;
}

.footer .is-expanded.menu__item {
  list-style: none;
}

.footer__contents {
  width: 100%;
  background-color: inherit;
  position: relative;
  padding: 0;
}

.footer__contents .credits {
  text-align: center;
  font-size: 75%;
  color: #3d3d3d;
  clear: both;
  margin-bottom: 0;
  color: #999999;
}

.footer__contents .credits a[href],
.footer__contents .credits a:link,
.footer__contents .credits a:visited {
  color: #999999;
  border-color: #cccccc;
}

.footer__contents .credits a:hover {
  color: black;
}

.footer__navigation {
  padding-top: 20px;
}

.footer__navigation__title {
  margin-bottom: 0;
}

.footer__navigation__list {
  margin-top: 0;
  list-style: none;
}

.footer__navigation__list a:link,
.footer__navigation__list a:visited {
  color: black;
  text-decoration: none;
  border-bottom: 0;
}

.footer footer.region-footer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  clear: both;
}

@media (min-width: 777px) {
  .footer footer.region-footer {
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: flex-start;
  }
}

.footer .block {
  padding: 0 0.6rem;
  margin-bottom: 0;
  min-width: 300px;
  width: 100%;
  max-width: 100%;
}

@media (min-width: 444px) {
  .footer .block {
    width: 100%;
    max-width: 460px;
  }
}

@media (min-width: 777px) {
  .footer .block {
    width: 100%;
    border-bottom: 0;
  }
}

@media (min-width: 777px) {
  .footer .block:last-child {
    margin-right: 0;
  }
}

@media (min-width: 777px) {
  .footer .block-block-2 {
    flex-shrink: 2;
  }
}

.footer .block-views-featured-supporters-block a[href],
.footer .block-views-featured-supporters-block a:link,
.footer .block-views-featured-supporters-block a:visited {
  border-bottom: 0;
  color: #000;
}

.footer .block-views-featured-supporters-block img {
  width: 100%;
  max-width: 300px;
  min-width: 150px;
  opacity: 0.76;
}

.footer .block-views-featured-supporters-block a:hover img {
  opacity: 1;
}

@media (min-width: 777px) {
  .footer .block-views-featured-supporters-block {
    flex-shrink: 2;
    max-width: 300px;
    min-width: 120px;
  }
}

.footer .footer__strapline {
  max-width: 100%;
  text-align: center;
  font-size: 110%;
  border-bottom: 1px solid #c4e7df;
}

.footer .credits {
  padding: 0 10px;
}

.footer .social-media-icons a {
  border: 0;
}

.footer .social-media-icon {
  width: 2rem;
  height: 2rem;
  overflow: hidden;
  display: inline-block;
  fill: #3d3d3d;
  border-width: 0;
  margin-right: 0.6em;
}

.footer .social-media-icon svg {
  width: 100%;
  height: 100%;
  fill: inherit;
}

.footer .social-media-icon:hover,
.footer .social-media-icon:active {
  fill: #84c556;
}

.region-footer {
  max-width: 1160px;
  margin: 0 auto;
  border-bottom: 1px solid #c4e7df;
  padding-top: 1.2rem;
  margin-bottom: 1.2rem;
}

.comment,
.forum--topic-root {
  max-width: 50rem;
  margin: 0 auto;
  border-bottom: 1px solid #cbeae3;
  margin-bottom: 1.2rem;
  width: 50rem;
  max-width: 100%;
}

.comment .username,
.forum--topic-root .username {
  color: #666666;
}

.comment .submitted,
.forum--topic-root .submitted {
  font-size: 90%;
  color: #999999;
  float: left;
}

.comment .submitted a:link,
.comment .submitted a:visited,
.forum--topic-root .submitted a:link,
.forum--topic-root .submitted a:visited {
  border-bottom: 0;
}

.comment .links,
.comment .inline.links,
.forum--topic-root .links,
.forum--topic-root .inline.links {
  float: right;
  margin-top: 0;
  text-transform: capitalize;
  color: #999999;
}

.comment .links li,
.comment .inline.links li,
.forum--topic-root .links li,
.forum--topic-root .inline.links li {
  margin-left: 0.8rem;
  padding: 5px 10px;
  border: 1px solid #aeded3;
}

.comment .links a:link,
.comment .links a:visited,
.comment .links a:hover,
.comment .links a:active,
.comment .inline.links a:link,
.comment .inline.links a:visited,
.comment .inline.links a:hover,
.comment .inline.links a:active,
.forum--topic-root .links a:link,
.forum--topic-root .links a:visited,
.forum--topic-root .links a:hover,
.forum--topic-root .links a:active,
.forum--topic-root .inline.links a:link,
.forum--topic-root .inline.links a:visited,
.forum--topic-root .inline.links a:hover,
.forum--topic-root .inline.links a:active {
  border-bottom: 0;
  color: inherit;
}

.comment .links .comment-reply,
.comment .inline.links .comment-reply,
.forum--topic-root .links .comment-reply,
.forum--topic-root .inline.links .comment-reply {
  background-color: #aeded3;
  color: #000;
}

.comment-form--body-field {
  margin-bottom: 0.8rem;
}

.comment-form--author {
  text-align: right;
  margin-bottom: 0.8rem;
}

.comment-form--author div {
  display: inline;
}

.main .forum--breadcrumbs {
  max-width: 50rem;
  margin: 0 auto;
}

.main .forum--breadcrumbs a:link,
.main .forum--breadcrumbs a:visited {
  border-bottom: 0;
}

.main .forum--topic-list {
  width: calc(100% + 20px);
  max-width: none;
  border: 0;
  margin-left: -10px;
  margin-right: -10px;
}

.main .forum--topic-list th {
  text-align: left;
  font-weight: 200;
  font-size: 130%;
  padding: .8rem .8rem 2.2rem;
  border-bottom: 4px solid #cbeae3;
}

.main .forum--topic-list th a {
  border-bottom: 0;
  color: #000;
}

.main .forum--topic-list th img {
  margin: 0 .3rem;
}

.main .forum--topic-list td {
  padding: .8rem;
  border-bottom: 1px solid #cbeae3;
}

.main .forum--topic-list td .title {
  font-size: 130%;
}

.main .forum--topic-list td.icon {
  text-align: center;
}

.main .forum--topic-list td.icon div {
  display: inline-block;
}

.main .forum--topic-list td.last-reply {
  white-space: normal;
}

.main .forum .indented {
  margin-left: 0;
  padding-left: 2vw;
  background-image: url("/sites/all/themes/pcn/assets/img/forum-response.png");
  background-repeat: no-repeat;
  background-position: top left;
}

@media (min-width: 777px) {
  .main .forum .indented {
    padding-left: 2.4rem;
    background-position: 10px top;
  }
}

.main .forum .submitted {
  font-size: 90%;
  color: #999999;
  float: left;
  white-space: normal;
  margin-bottom: 0;
}

.main .forum .submitted a:link,
.main .forum .submitted a:visited {
  border-bottom: 0;
}

.main .forum .new {
  text-transform: uppercase;
}

.main .forum .forum--topic-root {
  border-bottom: 1px solid #cbeae3;
}

.main .forum .forum--topic-foot {
  max-width: 50rem;
  margin: 0 auto;
  overflow: hidden;
}

.main .forum .forum--topic-reply-thread {
  max-width: 50rem;
  margin: 0 auto;
}

.header {
  background-color: white;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  padding: 10px 0 10px 5px;
}

@media (min-width: 777px) {
  .header {
    padding: 20px 0;
  }
}

@media (min-width: 1111px) {
  .header {
    margin-left: -10px;
  }
}

.header a:link,
.header a:visited {
  border-bottom: 0;
}

.header__logo {
  float: left;
  margin: 0 10px 0 0;
  padding: 0;
  max-width: 45%;
  border-bottom: 0;
}

[dir="rtl"] .header__logo {
  float: right;
  margin: 0 0 0 10px;
}

.header__logo-image {
  vertical-align: bottom;
  max-height: 100px;
}

.header__name-and-slogan {
  float: left;
}

.header__site-name {
  margin: 0;
  line-height: 1;
  font-size: 16px;
  font-weight: normal;
  max-width: 120px;
  float: left;
}

.header__site-link:link, .header__site-link:visited {
  color: #000;
  text-decoration: none;
  border-bottom: 0;
}

.header__site-link:hover, .header__site-link:focus {
  text-decoration: underline;
  border-bottom: 0;
}

.header__site-slogan {
  margin: 0;
}

.header__secondary-menu {
  float: right;
}

[dir="rtl"] .header__secondary-menu {
  float: left;
}

.header__region {
  clear: both;
}

.hidden,
html.js .js-hidden,
html.js .element-hidden,
html.js .js-hide {
  display: none;
}

.highlight-mark,
.new,
.update {
  color: #e93125;
  background-color: transparent;
}

.inline-links,
.inline.links {
  padding: 0;
}

.inline-links__item,
.inline.links li {
  display: inline;
  list-style-type: none;
  padding: 0 1em 0 0;
}

[dir="rtl"] .inline-links__item,
[dir="rtl"] .inline.links li,
.inline.links [dir="rtl"] li {
  display: inline-block;
  padding: 0 0 0 1em;
}

.inline-sibling,
.field-label-inline .field-label,
span.field-label {
  display: inline;
  margin-right: 10px;
}

[dir="rtl"] .inline-sibling,
[dir="rtl"] .field-label-inline .field-label,
.field-label-inline [dir="rtl"] .field-label,
[dir="rtl"] span.field-label {
  margin-right: 0;
  margin-left: 10px;
}

.inline-sibling__child,
.inline-sibling *, .field-label-inline .field-label *, span.field-label * {
  display: inline;
}

.inline-sibling__adjacent,
.inline-sibling + *,
.inline-sibling + * > :first-child,
.inline-sibling + * > :first-child > :first-child, .field-label-inline .field-label + *, span.field-label + *, .field-label-inline .field-label + * > :first-child, span.field-label + * > :first-child, .field-label-inline .field-label + * > :first-child > :first-child, span.field-label + * > :first-child > :first-child {
  display: inline;
}

.field-label-inline .field-label,
.field-label-inline .field-items {
  float: none;
}

.join-membership-header {
  text-align: center;
  font-size: 120%;
  margin-top: 0.8rem;
}

@media (min-width: 777px) {
  .join-membership-header {
    font-size: 160%;
  }
}

.join-membership-options {
  width: 100%;
  margin-bottom: 1.2rem;
  overflow: hidden;
  clear: both;
}

.join-membership-options .join-membership-option {
  position: relative;
  display: block;
  max-width: 500px;
  width: 75%;
  margin: 0 auto 0;
  min-width: 300px;
  text-align: left;
}

@media (min-width: 777px) {
  .join-membership-options .join-membership-option {
    max-width: 50%;
    float: left;
  }
}

.join-membership-options .join-membership-option-header {
  text-align: center;
  margin-top: 0;
  font-weight: bold;
}

.join-membership-options .join-membership-option-content {
  background-color: #82cdbc;
  padding: 5%;
  display: block;
  color: black;
  border: 4px solid #82cdbc;
  box-sizing: border-box;
}

.join-membership-options .join-membership-option-content:hover, .join-membership-options .join-membership-option-content:active {
  background-color: #98d6c8;
  border: 4px solid #000;
}

.join-membership-options .join-membership-features {
  list-style: none;
  text-align: left;
  font-weight: bold;
  line-height: 2;
  font-size: 110%;
}

.join-membership-options .join-membership-buy {
  text-align: center;
  color: black;
  font-weight: bold;
  display: block;
  border: 0;
  max-width: 30%;
  margin: 0 auto 0;
  padding: 0.3rem;
}

.join-membership-options .join-membership-buy-price {
  font-size: 180%;
  clear: both;
  line-height: 1.2;
}

.join-membership-options .join-membership-buy-price-term {
  display: block;
  font-size: 40%;
  font-weight: 300;
}

.join-membership-options .join-membership-buy-concessionary {
  font-size: 60%;
  color: #000;
  font-weight: 300;
  border: 0;
  max-width: none;
  display: block;
  margin: 0.6rem auto 1.2rem;
  text-align: center;
}

.join-membership-options .join-membership-buy-concessionary .join-membership-buy-price-term {
  display: inline;
  font-size: 100%;
}

.join-membership-options p {
  font-size: 90%;
}

.join-membership-options s {
  color: #999999;
  font-weight: 300;
}

.join .join-membership-option-free {
  padding: 2.5% 5%;
}

.join .join-membership-option-free .join-membership-option-header {
  font-size: 140%;
  font-weight: 300;
}

.join .join-membership-option-free .join-membership-features {
  font-weight: 300;
  font-size: 90%;
}

.join .join-membership-option-free .join-membership-option-content {
  background-color: #aeded3;
  opacity: 0.7;
  border: 4px solid #aeded3;
}

.join .join-membership-option-free .join-membership-option-content:hover, .join .join-membership-option-free .join-membership-option-content:active {
  background-color: #c4e7df;
}

.cke_editable,
main,
[role='main'],
.main {
  padding-top: 5px;
  margin: 0 auto;
  max-width: 1160px;
}

@media (min-width: 777px) {
  .cke_editable,
  main,
  [role='main'],
  .main {
    padding-top: 20px;
  }
}

.cke_editable header,
.cke_editable h1,
.cke_editable h2,
.cke_editable h3,
.cke_editable h4,
.cke_editable h5,
.cke_editable h6,
.cke_editable p,
.cke_editable ul,
.cke_editable ol,
.cke_editable blockquote,
.cke_editable pre,
.cke_editable form,
main header,
main h1,
main h2,
main h3,
main h4,
main h5,
main h6,
main p,
main ul,
main ol,
main blockquote,
main pre,
main form,
[role='main'] header,
[role='main'] h1,
[role='main'] h2,
[role='main'] h3,
[role='main'] h4,
[role='main'] h5,
[role='main'] h6,
[role='main'] p,
[role='main'] ul,
[role='main'] ol,
[role='main'] blockquote,
[role='main'] pre,
[role='main'] form,
.main header,
.main h1,
.main h2,
.main h3,
.main h4,
.main h5,
.main h6,
.main p,
.main ul,
.main ol,
.main blockquote,
.main pre,
.main form {
  max-width: 50rem;
  margin-left: auto;
  margin-right: auto;
}

.cke_editable header:empty,
.cke_editable h1:empty,
.cke_editable h2:empty,
.cke_editable h3:empty,
.cke_editable h4:empty,
.cke_editable h5:empty,
.cke_editable h6:empty,
.cke_editable p:empty,
.cke_editable ul:empty,
.cke_editable ol:empty,
.cke_editable blockquote:empty,
.cke_editable pre:empty,
.cke_editable form:empty,
main header:empty,
main h1:empty,
main h2:empty,
main h3:empty,
main h4:empty,
main h5:empty,
main h6:empty,
main p:empty,
main ul:empty,
main ol:empty,
main blockquote:empty,
main pre:empty,
main form:empty,
[role='main'] header:empty,
[role='main'] h1:empty,
[role='main'] h2:empty,
[role='main'] h3:empty,
[role='main'] h4:empty,
[role='main'] h5:empty,
[role='main'] h6:empty,
[role='main'] p:empty,
[role='main'] ul:empty,
[role='main'] ol:empty,
[role='main'] blockquote:empty,
[role='main'] pre:empty,
[role='main'] form:empty,
.main header:empty,
.main h1:empty,
.main h2:empty,
.main h3:empty,
.main h4:empty,
.main h5:empty,
.main h6:empty,
.main p:empty,
.main ul:empty,
.main ol:empty,
.main blockquote:empty,
.main pre:empty,
.main form:empty {
  display: none;
}

.cke_editable blockquote,
main blockquote,
[role='main'] blockquote,
.main blockquote {
  padding-left: 5%;
  padding-right: 5%;
  font-style: italic;
  position: relative;
  font-family: georgia, times, serif;
}

.cke_editable blockquote i,
.cke_editable blockquote em,
main blockquote i,
main blockquote em,
[role='main'] blockquote i,
[role='main'] blockquote em,
.main blockquote i,
.main blockquote em {
  font-style: normal;
}

.cke_editable blockquote p,
main blockquote p,
[role='main'] blockquote p,
.main blockquote p {
  position: relative;
  z-index: 2;
}

.cke_editable .align_left,
main .align_left,
[role='main'] .align_left,
.main .align_left {
  float: left;
  clear: left;
  width: 100%;
}

@media (min-width: 666px) {
  .cke_editable .align_left,
  main .align_left,
  [role='main'] .align_left,
  .main .align_left {
    max-width: 50%;
  }
}

.cke_editable .align_right,
main .align_right,
[role='main'] .align_right,
.main .align_right {
  float: right;
  clear: right;
  width: 100%;
}

@media (min-width: 666px) {
  .cke_editable .align_right,
  main .align_right,
  [role='main'] .align_right,
  .main .align_right {
    max-width: 50%;
  }
}

.cke_editable .align_center,
main .align_center,
[role='main'] .align_center,
.main .align_center {
  clear: both;
  margin-left: auto;
  margin-right: auto;
}

.cke_editable .align_justify,
main .align_justify,
[role='main'] .align_justify,
.main .align_justify {
  clear: both;
  width: 100%;
  max-width: 1160px;
  margin: 0 auto 1.6rem auto;
}

.cke_editable .image-field a,
main .image-field a,
[role='main'] .image-field a,
.main .image-field a {
  border: 0;
}

.cke_editable img,
.cke_editable figure,
main img,
main figure,
[role='main'] img,
[role='main'] figure,
.main img,
.main figure {
  margin: 0 0 1.6rem 0;
}

.cke_editable img img,
.cke_editable figure img,
main img img,
main figure img,
[role='main'] img img,
[role='main'] figure img,
.main img img,
.main figure img {
  margin: 0;
}

.cke_editable img figcaption,
.cke_editable figure figcaption,
main img figcaption,
main figure figcaption,
[role='main'] img figcaption,
[role='main'] figure figcaption,
.main img figcaption,
.main figure figcaption {
  color: grey;
  font-style: italic;
}

.cke_editable img figcaption i,
.cke_editable img figcaption em,
.cke_editable figure figcaption i,
.cke_editable figure figcaption em,
main img figcaption i,
main img figcaption em,
main figure figcaption i,
main figure figcaption em,
[role='main'] img figcaption i,
[role='main'] img figcaption em,
[role='main'] figure figcaption i,
[role='main'] figure figcaption em,
.main img figcaption i,
.main img figcaption em,
.main figure figcaption i,
.main figure figcaption em {
  font-style: normal;
}

.cke_editable figure img,
.cke_editable figure video,
.cke_editable figure audio,
.cke_editable figure iframe,
main figure img,
main figure video,
main figure audio,
main figure iframe,
[role='main'] figure img,
[role='main'] figure video,
[role='main'] figure audio,
[role='main'] figure iframe,
.main figure img,
.main figure video,
.main figure audio,
.main figure iframe {
  max-width: 100%;
}

.cke_editable .node,
main .node,
[role='main'] .node,
.main .node {
  margin-bottom: 2rem;
}

@media (min-width: 666px) {
  .cke_editable img[style='float:left'],
  .cke_editable figure[style='float:left'],
  .cke_editable img.align_left,
  .cke_editable figure.align_left,
  main img[style='float:left'],
  main figure[style='float:left'],
  main img.align_left,
  main figure.align_left,
  [role='main'] img[style='float:left'],
  [role='main'] figure[style='float:left'],
  [role='main'] img.align_left,
  [role='main'] figure.align_left,
  .main img[style='float:left'],
  .main figure[style='float:left'],
  .main img.align_left,
  .main figure.align_left {
    margin: 0 1.6rem 1.6rem 0;
    max-width: 50%;
  }
  .cke_editable img[style='float:right'],
  .cke_editable figure[style='float:right'],
  .cke_editable img.align_right,
  .cke_editable figure.align_right,
  main img[style='float:right'],
  main figure[style='float:right'],
  main img.align_right,
  main figure.align_right,
  [role='main'] img[style='float:right'],
  [role='main'] figure[style='float:right'],
  [role='main'] img.align_right,
  [role='main'] figure.align_right,
  .main img[style='float:right'],
  .main figure[style='float:right'],
  .main img.align_right,
  .main figure.align_right {
    margin: 0 0 1.6rem 1.6rem;
    max-width: 50%;
  }
  .cke_editable img.align_center,
  .cke_editable figure.align_center,
  main img.align_center,
  main figure.align_center,
  [role='main'] img.align_center,
  [role='main'] figure.align_center,
  .main img.align_center,
  .main figure.align_center {
    max-width: 750px;
    height: auto;
    margin: 0 auto 1.6rem auto;
  }
}

.cke_editable table,
main table,
[role='main'] table,
.main table {
  margin: 0 auto 1.6rem auto;
  width: auto;
  max-width: 100%;
  border: 1px solid black;
}

.cke_editable table caption,
.cke_editable table th,
.cke_editable table td,
main table caption,
main table th,
main table td,
[role='main'] table caption,
[role='main'] table th,
[role='main'] table td,
.main table caption,
.main table th,
.main table td {
  padding: 3px 5px;
}

.cke_editable table caption,
.cke_editable table th,
main table caption,
main table th,
[role='main'] table caption,
[role='main'] table th,
.main table caption,
.main table th {
  font-weight: bold;
}

.cke_editable img[src^='//cdn.ckeditor.com'],
main img[src^='//cdn.ckeditor.com'],
[role='main'] img[src^='//cdn.ckeditor.com'],
.main img[src^='//cdn.ckeditor.com'] {
  margin: 0;
  vertical-align: baseline;
  margin-bottom: -.35rem;
}

.cke_editable img.file-icon,
main img.file-icon,
[role='main'] img.file-icon,
.main img.file-icon {
  margin: 0;
}

.cke_editable h4,
.cke_editable h5,
.cke_editable h6,
main h4,
main h5,
main h6,
[role='main'] h4,
[role='main'] h5,
[role='main'] h6,
.main h4,
.main h5,
.main h6 {
  font-size: 1.1em;
}

.cke_editable h4,
main h4,
[role='main'] h4,
.main h4 {
  font-weight: bold;
}

.cke_editable h5,
main h5,
[role='main'] h5,
.main h5 {
  font-style: italic;
}

.cke_editable .field-type-link-field,
main .field-type-link-field,
[role='main'] .field-type-link-field,
.main .field-type-link-field {
  max-width: 100%;
  overflow: hidden;
  text-overflow: elipsis;
}

.membership-prompt {
  border: 4px solid #cbeae3;
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: 1.6rem;
  margin-top: -10px;
  padding: 0.8rem;
  border-top-width: 0px;
  text-align: center;
}

@media (min-width: 777px) {
  .membership-prompt {
    margin-top: -20px;
  }
}

.membership-prompt p {
  margin-bottom: 0;
}

.membership-prompt p:first-child {
  font-size: 120%;
}

.membership-prompt .join-prompt-concessionary {
  color: #999999;
  margin-top: 0;
}

.messages,
.messages--status {
  margin: 1.6rem 0;
  position: relative;
  padding: 10px 10px 10px 44px;
  border: 1px solid #39c6f4;
}

[dir="rtl"] .messages,
[dir="rtl"] .messages--status {
  padding: 10px 44px 10px 10px;
  background-position: 99% 8px;
}

.messages__icon {
  position: absolute;
  top: 50%;
  left: 10px;
  height: 24px;
  width: 24px;
  margin-top: -12px;
  line-height: 1;
}

[dir="rtl"] .messages__icon {
  left: auto;
  right: 0;
}

.messages__icon path {
  fill: #39c6f4;
}

.messages__highlight,
.messages--error .error,
.messages.error .error {
  color: #000;
}

.messages__list {
  margin: 0;
}

.messages__item {
  list-style-image: none;
}

.messages--ok-color,
.messages,
.messages--status,
.ok,
.revision-current {
  background-color: white;
  color: #39c6f4;
}

.messages--warning-color,
.messages--warning,
.messages.warning,
.warning {
  background-color: white;
  color: #000;
}

.messages--error-color,
.messages--error,
.messages.error,
.error {
  background-color: white;
  color: #e93125;
}

.messages--warning,
.messages.warning {
  border-color: #f8c746;
}

.messages--warning path, .messages.warning path {
  fill: #f8c746;
}

.messages--error,
.messages.error {
  border-color: #e93125;
}

.messages--error path, .messages.error path {
  fill: #e93125;
}

.field-group-multipage .multipage-counter {
  display: block;
  text-align: right;
  clear: both;
  float: none;
  margin: 0.6rem 0;
  top: auto;
  height: auto;
  font: inherit;
  font-size: 120%;
}

.field-group-multipage .multipage-counter:before {
  content: 'Step: ';
}

.field-group-multipage .multipage-counter em {
  font-style: normal;
}

.field-group-multipage .multipage-controls-list {
  text-align: right;
}

.field-group-multipage .multipage-controls-list .multipage-button {
  float: none;
  font: inherit;
}

.field-group-multipage .multipage-controls-list input.form-submit {
  margin-left: 0.6rem;
  border-radius: 0;
  border-width: 1px;
  font-weight: 300;
  font-size: 1rem;
  font-family: 'Neue Helvetica', helvetica, arial, sans-serif;
  border: 1px solid #94d4c6;
  border-color: white #94d4c6 #94d4c6 white;
  background: #cbeae3;
  text-shadow: none;
}

.field-group-multipage .multipage-controls-list input.form-submit:hover,
.field-group-multipage .multipage-controls-list input.form-submit:active {
  border: 1px solid #94d4c6;
  border-color: #94d4c6 white white #94d4c6;
  background: #a6dcd0;
}

.field-group-multipage .multipage-controls-list .form-actions {
  float: right;
}

.field-group-multipage .multipage-controls-list #edit-preview {
  display: none;
}

.newsletter-signup, .block-pcnx-newsletter-signup {
  border-top: 4px solid #d9f0eb;
  margin-left: -10px;
  margin-right: -10px;
  margin-top: 1.6rem;
  padding: 0.8rem;
  border-bottom-width: 0px;
  text-align: center;
}

.newsletter-signup p:first-child, .block-pcnx-newsletter-signup p:first-child {
  margin-bottom: 0.3rem;
}

.newsletter-signup fieldset, .block-pcnx-newsletter-signup fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

.newsletter-signup .form-item, .block-pcnx-newsletter-signup .form-item {
  margin: 0;
}

.newsletter-signup--form, .newsletter-signup .fieldset-wrapper, .block-pcnx-newsletter-signup--form, .block-pcnx-newsletter-signup .fieldset-wrapper {
  max-width: 25rem;
  margin: 0 auto 0.3rem;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.newsletter-signup--form--email, .newsletter-signup--form .form-item-email, .newsletter-signup .fieldset-wrapper--email, .newsletter-signup .fieldset-wrapper .form-item-email, .block-pcnx-newsletter-signup--form--email, .block-pcnx-newsletter-signup--form .form-item-email, .block-pcnx-newsletter-signup .fieldset-wrapper--email, .block-pcnx-newsletter-signup .fieldset-wrapper .form-item-email {
  display: inline-block;
  margin-right: 1.6rem;
  font-size: 1rem;
  box-sizing: border-box;
  height: 2.4rem;
}

.newsletter-signup--form--button, .newsletter-signup--form .form-submit, .newsletter-signup .fieldset-wrapper--button, .newsletter-signup .fieldset-wrapper .form-submit, .block-pcnx-newsletter-signup--form--button, .block-pcnx-newsletter-signup--form .form-submit, .block-pcnx-newsletter-signup .fieldset-wrapper--button, .block-pcnx-newsletter-signup .fieldset-wrapper .form-submit {
  display: inline-block;
  margin: 0;
  font-size: 1rem;
  padding: 0 1.6rem;
  box-sizing: border-box;
  height: 2.4rem;
}

.photo-collection {
  background-color: #000;
  font-weight: 200;
  color: #fff;
  padding-bottom: 10px;
}

.photo-collection p {
  line-height: 1.5;
  max-width: 65em;
}

.photo-collection p:first-child {
  margin-top: 0;
}

.photo-collection p:last-child {
  margin-bottom: 0;
}

.photo-collection__header {
  padding: 15px 10px;
}

.photo-collection__header h1,
.photo-collection__header h2,
.photo-collection__header h4 {
  font-weight: 200;
  line-height: 1.2;
  padding: 0;
  margin: 0;
}

.photo-collection__header h1 a[href],
.photo-collection__header h1 a:link,
.photo-collection__header h1 a:visited {
  border-width: 0;
}

.photo-collection__header h2,
.photo-collection__header h4 {
  color: #999999;
}

.photo-collection__header a:link,
.photo-collection__header a:visited {
  color: inherit;
  border-bottom-color: #999999;
}

.photo-collection .field-type-link-field {
  max-width: 100%;
  overflow: hidden;
  text-overflow: elipsis;
}

.photo-collection__photos {
  align-content: center;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-around;
  overflow: auto;
  max-width: 100%;
}

.photo-collection__photos figure {
  margin: 0;
  box-sizing: border-box;
  padding: 0 5px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: .8rem 0;
}

.photo-collection__photos figure img {
  max-width: 30vw;
}

.photo-collection__photos figure figcaption {
  font-size: 75%;
  padding: 10px 5px;
  box-sizing: border-box;
  position: absolute;
  bottom: -20px;
  background-color: rgba(0, 0, 0, 0.5);
  max-height: 0;
  overflow: hidden;
  transition: max-height .4s, bottom .4s;
  left: 0;
  right: 0;
  line-height: 1.5;
}

.photo-collection__photos figure:hover figcaption,
.photo-collection__photos figure:active figcaption {
  max-height: 100%;
  bottom: 0;
}

.photo-collection__footer {
  padding: 10px 10px 0;
  font-size: 80%;
  color: #999999;
}

.photo-collection__footer p:last-of-type {
  margin-bottom: 0;
}

@media print {
  .print-none,
  .toolbar,
  .action-links,
  .links,
  .book-navigation,
  .forum-topic-navigation,
  .feed-icons {
    display: none;
  }
}

.view-registration-required {
  border-bottom: 4px solid #cbeae3;
}

.block-user-login--2 .form-item-name .description {
  display: none;
}

.responsive-video,
.media-youtube-video,
.media-vimeo-preview-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
}

.responsive-video__embed,
.responsive-video iframe,
.media-youtube-video iframe,
.media-vimeo-preview-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.responsive-video--4-3 {
  padding-bottom: 75%;
}

.main .search-form {
  max-width: none;
  border-bottom: 4px solid #cbeae3;
}

.main .search-form fieldset {
  max-width: 50rem;
  margin: 0 auto;
  border: 0;
}

.main .search-form fieldset.container-inline {
  padding: 1.6rem 0;
}

.main .search-form fieldset.search-advanced {
  border: 1px solid #cbeae3;
}

.main .search-form fieldset.search-advanced.collapsed {
  border-width: 1px 0 0 0;
}

.main .search-form fieldset:last-child {
  margin-bottom: 2.4rem;
}

.main .search-form .container-inline legend {
  display: none;
}

.main .search-form .container-inline .fieldset-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.main .search-form .container-inline .fieldset-wrapper .form-item {
  margin: 0 1.6rem 0 0;
  flex-grow: 4;
}

.main .search-form .container-inline .fieldset-wrapper .form-text {
  display: inline-block;
  margin-right: 1.6rem;
  font-size: 1rem;
  box-sizing: border-box;
  height: 2.4rem;
}

.main .search-form .container-inline .fieldset-wrapper .form-submit {
  display: inline-block;
  margin: 0;
  font-size: 1rem;
  padding: 0 1.6rem;
  box-sizing: border-box;
  height: 2.4rem;
}

.main .search-form:last-child {
  border-bottom-width: 0;
}

.main .search-results {
  max-width: 100%;
  margin: 0;
  padding: 0;
}

.main .search-results .search-result {
  border-bottom: 1px solid #cbeae3;
}

.main .search-results .search-result h3,
.main .search-results .search-result div {
  max-width: 50rem;
  margin: 0 auto;
}

.main .search-results .search-snippet-info {
  padding-left: 0;
}

.main .block-views-supporters-block,
.main .block-views-supporters-level-2-block {
  border-top-width: 0;
  padding-top: 0;
}

.main .block-views-supporters-block p,
.main .block-views-supporters-level-2-block p {
  margin-bottom: 0;
}

.view-supporters,
.view-supporters-level-2 {
  max-width: 100%;
}

.view-supporters .view-content,
.view-supporters-level-2 .view-content {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.view-supporters .view-content .views-row,
.view-supporters-level-2 .view-content .views-row {
  display: inline-block;
  box-sizing: border-box;
  padding: 1.2rem;
  border-bottom: 0;
  min-width: 320px;
  width: 30%;
}

.node-supporter .logo-wrapper {
  position: relative;
  display: block;
  max-width: 300px;
  max-height: 120px;
  overflow: hidden;
  border-bottom: 0;
}

.node-supporter .logo-wrapper .logo-sizer {
  height: 0;
  width: 100%;
  padding-bottom: 33%;
}

.node-supporter .logo-wrapper .field-item {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.node-supporter .logo-wrapper .field-item img {
  position: relative;
  max-height: 100%;
  max-width: 100%;
  margin: 0;
}

.node-supporter h1 {
  font-size: 1.4rem;
  margin: 0;
}

.node-supporter a:link,
.node-supporter a:visited {
  border-bottom: 0;
}

.node-supporter p {
  margin-bottom: 0;
}

.main .block-views-registration-required-block {
  border-top: 0;
  padding-top: 0;
}

.visually-hidden,
.element-invisible,
.element-focusable,
.breadcrumb__title,
.main-navigation .block-menu .block__title,
.main-navigation .block-menu-block .block__title {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  word-wrap: normal;
}

.visually-hidden--off,
.visually-hidden--focusable:active,
.visually-hidden--focusable:focus,
.element-focusable:active,
.element-focusable:focus {
  position: static !important;
  clip: auto;
  height: auto;
  width: auto;
  overflow: visible;
}

.watermark {
  font-weight: bold;
  text-transform: uppercase;
  display: block;
  height: 0;
  overflow: visible;
  background-color: transparent;
  color: #eeeeee;
  font-size: 75px;
  line-height: 1;
  text-align: center;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
  word-wrap: break-word;
  position: absolute;
  z-index: -1;
  text-align: center;
  width: 50rem;
  max-width: 100%;
}

@media print {
  .breadcrumb {
    display: none;
  }
}

.breadcrumb__list {
  margin: 0;
  padding: 0;
}

.breadcrumb__item {
  display: inline;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.more-link,
.more-help-link {
  text-align: right;
}

[dir="rtl"] .more-link,
[dir="rtl"] .more-help-link {
  text-align: left;
}

.more-link__help-icon,
.more-help-link a {
  padding: 1px 0 1px 20px;
  background-image: url(../sass/navigation/more-link/more-link__help-icon.svg);
  background-position: 0 50%;
  background-repeat: no-repeat;
}

[dir="rtl"] .more-link__help-icon,
[dir="rtl"] .more-help-link a,
.more-help-link [dir="rtl"] a {
  padding: 1px 20px 1px 0;
  background-position: 100% 50%;
}

.nav-menu--functions {
  overflow: hidden;
  padding: 0.8rem 0 0.8rem 0;
}

@media (min-width: 777px) {
  .nav-menu--functions {
    clear: both;
    padding-top: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: -142px;
  }
  .nav-menu--functions .block {
    float: right;
    clear: none;
    margin-top: 1rem;
  }
}

.nav-menu__wrapper, .nav-menu__expander {
  background-color: #82cdbc;
  min-height: 2px;
}

.nav-menu__item,
.menu__item {
  list-style-image: url(../sass/navigation/nav-menu/leaf.svg);
  list-style-type: square;
}

.nav-menu__item.is-expanded, .nav-menu__item--is-expanded,
.is-expanded.menu__item,
.menu__item.is-expanded {
  list-style-image: url(../sass/navigation/nav-menu/expanded.svg);
  list-style-type: circle;
}

.nav-menu__item.is-collapsed, .nav-menu__item--is-collapsed,
.is-collapsed.menu__item,
.menu__item.is-collapsed {
  list-style-image: url(../sass/navigation/nav-menu/collapsed.svg);
  list-style-type: disc;
}

[dir="rtl"] .nav-menu__item.is-collapsed, [dir="rtl"] .nav-menu__item--is-collapsed,
[dir="rtl"] .is-collapsed.menu__item,
[dir="rtl"] .menu__item.is-collapsed {
  list-style-image: url(../sass/navigation/nav-menu/collapsed-rtl.svg);
}

.nav-menu__link.is-active, .nav-menu__link--is-active,
.menu a.active {
  color: #000;
}

.nav-menu__container {
  position: relative;
  padding: 0;
  width: 100%;
  background-color: #82cdbc;
}

.nav-menu__show {
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: helvetica, sans-serif;
  font-size: 70%;
  font-weight: bold;
  margin-top: -3px;
  position: absolute;
  top: -47px;
  right: 10px;
}

@media (min-width: 777px) {
  .nav-menu__show {
    display: none;
    position: absolute;
  }
}

.nav-menu__show:link, .nav-menu__showvivisted {
  border-bottom: 0;
}

.nav-menu__show-label {
  margin-top: -4px;
  clear: both;
}

.block-system-main-menu--wrapper {
  background-color: #82cdbc;
}

.block-system-main-menu {
  position: relative;
  clear: both;
}

.block-system-main-menu .menu {
  background-color: #82cdbc;
  margin: 0;
  padding-left: 0;
  position: relative;
  width: 100%;
}

.block-system-main-menu .menu .menu__item {
  font-weight: 200;
  list-style: none;
}

.block-system-main-menu .menu .menu__item a {
  position: relative;
  color: black;
  display: block;
  padding: 5px 15px;
  text-decoration: none;
  border-bottom: 0;
  white-space: nowrap;
}

.block-system-main-menu .menu .menu__item a:link,
.block-system-main-menu .menu .menu__item a:visited {
  color: black;
}

.block-system-main-menu .menu .menu__item a.is-active-trail::after {
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 100%;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border: solid transparent;
  border-top-color: #98d6c8;
  border-width: 10px;
  margin-left: -10px;
}

.block-system-main-menu .menu .menu {
  background-color: #aeded3;
}

.block-system-main-menu .menu .menu .menu__item {
  width: 100%;
  text-indent: 10px;
}

.block-system-main-menu .menu .menu .menu__item a.is-active-trail {
  background-color: #c4e7df;
}

.block-system-main-menu .menu .menu .menu__item a.is-active-trail::after {
  border-top-color: #c4e7df;
}

.block-system-main-menu .menu .menu a:hover {
  background-color: #c4e7df;
}

.block-system-main-menu .menu .is-active-trail {
  background-color: #98d6c8;
}

@media (min-width: 777px) {
  .block-system-main-menu .menu {
    display: flex;
    flex-direction: row;
    flex-wrap: none;
    justify-content: space-between;
  }
  .block-system-main-menu .menu .menu__item {
    display: block;
    text-align: center;
    width: auto;
  }
  .block-system-main-menu .menu .menu__item a {
    padding: 10px 15px;
  }
  .block-system-main-menu .menu .menu {
    left: 0;
    position: absolute;
    top: 44px;
    width: 100%;
  }
  .block-system-main-menu .menu .menu .menu__item {
    display: block;
    text-align: center;
    width: 100%;
  }
  .block-system-main-menu .menu .menu a:hover {
    background-color: #c4e7df;
  }
  .block-system-main-menu .menu .menu a.is-active-trail {
    background-color: #c4e7df;
  }
  .block-system-main-menu .menu .menu a.is-active-trail::after {
    border-top-color: #c4e7df;
  }
  .block-system-main-menu .is-expanded {
    margin-bottom: 44px;
  }
  .block-system-main-menu a:hover {
    background-color: #98d6c8;
  }
  .block-system-main-menu a.is-active-trail {
    background-color: #98d6c8;
    position: relative;
    color: #fff;
  }
  .block-system-main-menu a.is-active-trail::after {
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 100%;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border: solid transparent;
    border-top-color: #98d6c8;
    border-width: 10px;
    margin-left: -10px;
  }
}

.menu-name-user-menu,
.block-user-login {
  padding: 1rem 0;
}

.menu-name-user-menu .user--login--title,
.block-user-login .user--login--title {
  display: none;
}

.menu-name-user-menu .form-item,
.menu-name-user-menu .item-list,
.menu-name-user-menu .form-actions,
.block-user-login .form-item,
.block-user-login .item-list,
.block-user-login .form-actions {
  float: left;
  margin: 0 0 0 1rem;
}

.menu-name-user-menu .form-item .description,
.menu-name-user-menu .item-list .description,
.menu-name-user-menu .form-actions .description,
.block-user-login .form-item .description,
.block-user-login .item-list .description,
.block-user-login .form-actions .description {
  font-size: 70%;
}

.menu-name-user-menu .form-item [type='submit'],
.menu-name-user-menu .item-list [type='submit'],
.menu-name-user-menu .form-actions [type='submit'],
.block-user-login .form-item [type='submit'],
.block-user-login .item-list [type='submit'],
.block-user-login .form-actions [type='submit'] {
  padding: 0 10px;
  font-size: 80%;
  margin: 0;
  height: 1.7rem;
}

.menu-name-user-menu .form-item a:link,
.menu-name-user-menu .form-item a:visited,
.menu-name-user-menu .item-list a:link,
.menu-name-user-menu .item-list a:visited,
.menu-name-user-menu .form-actions a:link,
.menu-name-user-menu .form-actions a:visited,
.block-user-login .form-item a:link,
.block-user-login .form-item a:visited,
.block-user-login .item-list a:link,
.block-user-login .item-list a:visited,
.block-user-login .form-actions a:link,
.block-user-login .form-actions a:visited {
  color: #999999;
  border-color: #999999;
  border-bottom-width: 0;
}

.menu-name-user-menu .form-item a:active,
.menu-name-user-menu .form-item a:hover,
.menu-name-user-menu .item-list a:active,
.menu-name-user-menu .item-list a:hover,
.menu-name-user-menu .form-actions a:active,
.menu-name-user-menu .form-actions a:hover,
.block-user-login .form-item a:active,
.block-user-login .form-item a:hover,
.block-user-login .item-list a:active,
.block-user-login .item-list a:hover,
.block-user-login .form-actions a:active,
.block-user-login .form-actions a:hover {
  color: #84c556;
  border-color: #84c556;
}

.menu-name-user-menu label,
.block-user-login label {
  font-size: 80%;
}

.menu-name-user-menu .menu,
.block-user-login .menu {
  background: none;
}

.menu-name-user-menu .menu .menu__item,
.block-user-login .menu .menu__item {
  color: #999999;
}

.menu-name-user-menu .menu .menu__item a,
.block-user-login .menu .menu__item a {
  color: inherit;
  padding: 0 15px;
  line-height: 2.4rem;
}

.menu-name-user-menu .menu .menu__item a:link, .menu-name-user-menu .menu .menu__item a:visited,
.block-user-login .menu .menu__item a:link,
.block-user-login .menu .menu__item a:visited {
  color: inherit;
}

.menu-name-user-menu .menu .menu__item a:hover,
.block-user-login .menu .menu__item a:hover {
  color: black;
}

.menu-name-user-menu .menu .menu__item .username,
.block-user-login .menu .menu__item .username {
  color: black;
}

@media (min-width: 777px) {
  .menu-name-user-menu,
  .block-user-login {
    padding: 0 1rem;
    margin-left: 300px;
  }
}

.block-search {
  padding-left: 1rem;
  padding-right: 1rem;
}

.block-search [type='text'] {
  padding-right: 2rem;
  box-sizing: border-box;
}

.block-search .form-item {
  margin: 0;
}

.block-search .form-actions {
  position: absolute;
  right: 1em;
  top: 0;
}

.block-search .form-submit {
  position: relative;
  width: 1.65rem;
  height: 2.2rem;
  min-height: 0;
  padding: 0;
  margin: .1rem .4rem;
  box-sizing: border-box;
  border: 0;
  background-color: #fff;
  background-image: url("/sites/all/themes/pcn/assets/img/search.svg");
  background-position: center center;
  background-repeat: no-repeat;
  text-indent: 1000px;
}

.block-search .form-submit:hover {
  background-image: url("/sites/all/themes/pcn/assets/img/search-hover.svg");
}

@media (min-width: 777px) {
  .block-search .form-item,
  .block-search .item-list,
  .block-search .form-actions {
    float: left;
    margin: 0;
  }
  .block-search [type='text'] {
    width: 150px;
  }
  .block-search label {
    font-size: 80%;
  }
}

.navbar,
.main-navigation .links,
.main-navigation .menu {
  margin: 0;
  padding: 0;
  text-align: left;
}

[dir="rtl"] .navbar,
[dir="rtl"] .main-navigation .links,
.main-navigation [dir="rtl"] .links,
[dir="rtl"]
.main-navigation .menu,
.main-navigation [dir="rtl"] .menu {
  text-align: right;
}

.navbar__item,
.navbar li, .main-navigation .links li,
.main-navigation .menu li {
  float: left;
  padding: 0 10px 0 0;
  list-style-type: none;
  list-style-image: none;
}

[dir="rtl"] .navbar__item, [dir="rtl"]
.navbar li, [dir="rtl"] .main-navigation .links li, .main-navigation [dir="rtl"] .links li, [dir="rtl"]
.main-navigation .menu li,
.main-navigation [dir="rtl"] .menu li {
  float: right;
  padding: 0 0 0 10px;
}

.pager {
  clear: both;
  padding: 0;
  text-align: center;
  background-color: #d9f0eb;
}

@media print {
  .pager {
    display: none;
  }
}

.pager__item,
.pager__current-item,
.pager-current,
.pager-item,
.pager-first,
.pager-previous,
.pager-next,
.pager-last,
.pager-ellipsis {
  display: inline-block;
  padding: 0.3rem 0.6rem;
  list-style-type: none;
  background-image: none;
  margin: 0 0.15rem;
  position: relative;
}

.pager__item a:link,
.pager__item a:visited,
.pager__item a:hover,
.pager__item a:active, .pager__current-item a:link, .pager-current a:link, .pager-item a:link,
.pager-first a:link,
.pager-previous a:link,
.pager-next a:link,
.pager-last a:link,
.pager-ellipsis a:link, .pager__current-item a:visited, .pager-current a:visited, .pager-item a:visited,
.pager-first a:visited,
.pager-previous a:visited,
.pager-next a:visited,
.pager-last a:visited,
.pager-ellipsis a:visited, .pager__current-item a:hover, .pager-current a:hover, .pager-item a:hover,
.pager-first a:hover,
.pager-previous a:hover,
.pager-next a:hover,
.pager-last a:hover,
.pager-ellipsis a:hover, .pager__current-item a:active, .pager-current a:active, .pager-item a:active,
.pager-first a:active,
.pager-previous a:active,
.pager-next a:active,
.pager-last a:active,
.pager-ellipsis a:active {
  border: 0;
  color: #000;
}

.pager__current-item,
.pager-current {
  font-weight: bold;
  background-color: #c4e7df;
}

.pager__current-item:after,
.pager-current:after {
  position: absolute;
  z-index: 1;
  left: 50%;
  bottom: 100%;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border: solid transparent;
  border-bottom-color: #c4e7df;
  border-width: 8px;
  margin-left: -8px;
}

.main .pager {
  max-width: none;
  margin-left: -10px;
  margin-right: -10px;
}

.skip-link {
  display: block;
  padding: 2px 0 3px;
  text-align: center;
}

.skip-link:link, .skip-link:visited {
  background-color: #666666;
  color: #fff;
}

.skip-link__wrapper {
  margin: 0;
}

@media print {
  .skip-link__wrapper {
    display: none;
  }
}

.tabs {
  line-height: 2.88rem;
  position: relative;
  padding: 0 2px;
  list-style: none;
  white-space: nowrap;
  margin-top: -5px;
  margin-bottom: 20px;
  max-width: none;
  left: -10px;
  width: calc(100% + 20px);
  background-color: #d9f0eb;
  overflow: visible;
}

@media (min-width: 777px) {
  .tabs {
    margin-top: -20px;
  }
}

@media print {
  .tabs {
    display: none;
  }
}

.tabs__tab {
  position: relative;
  margin: 0 5px;
  background: #d9f0eb;
  padding: 0 1.6rem;
}

.tabs__tab a {
  color: #000;
  border-bottom: 0;
}

.tabs__tab:active, .tabs__tab.is-active, .tabs__tab--is-active {
  background: #eff9f7;
}

.tabs__tab.is-active::after {
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 100%;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border: solid transparent;
  border-top-color: #eff9f7;
  border-width: 10px;
  margin-left: -10px;
}

.tabs__tab-link {
  padding: 0 1.6rem;
  display: block;
  text-decoration: none;
  position: relative;
  color: #000;
  background: #d9f0eb;
  letter-spacing: 1px;
}

.tabs__tab-link:link, .tabs__tab-link:visited {
  color: #000;
}

.tabs__tab-link:focus, .tabs__tab-link:hover {
  background: #eff9f7;
}

.tabs__tab-link:active, .tabs__tab-link.is-active, .tabs__tab-link--is-active {
  background: #eff9f7;
}

.tabs--off,
.views-displays .secondary {
  margin: 0;
  border-bottom: 0;
  padding: 0;
  background-image: none;
}

.tabs--secondary {
  margin-top: 1.6rem;
  font-size: 0.93333rem;
  background-image: none;
}

.tabs + .tabs--secondary {
  margin-top: 0;
  background-image: linear-gradient(to top, #d9f0eb 1px, transparent 1px);
}

.tabs--secondary .tabs__tab {
  margin: 0.16rem 0.2rem;
  border: 0;
  background: transparent;
}

[dir="rtl"] .tabs--secondary .tabs__tab {
  float: right;
}

.tabs--secondary .tabs__tab.is-active {
  border-bottom-color: transparent;
}

.tabs--secondary .tabs__tab-link {
  border: 1px solid #d9f0eb;
  color: #aeded3;
  background: #f7fcfa;
  letter-spacing: normal;
}

.tabs--secondary .tabs__tab-link:focus, .tabs--secondary .tabs__tab-link:hover {
  color: #65c1ac;
  background: #d9f0eb;
  border-color: #79c9b7;
}

.tabs--secondary .tabs__tab-link:active, .tabs--secondary .tabs__tab-link.is-active, .tabs--secondary .tabs__tab-link--is-active {
  color: white;
  background: #aeded3;
  border-color: #378977;
}

@media (min-width: 777px) {
  .tabs {
    display: flex;
    flex-direction: row;
    flex-wrap: none;
    justify-content: space-between;
  }
}

[role='main'] .tabs {
  max-width: none;
}

.action-links {
  line-height: 2.88rem;
  position: relative;
  padding: 0 2px;
  list-style: none;
  white-space: nowrap;
  margin-top: -20px;
  margin-bottom: 20px;
  max-width: none;
  left: -10px;
  width: calc(100% + 20px);
  background-color: #d9f0eb;
  overflow: visible;
}

@media print {
  .action-links {
    display: none;
  }
}

.action-links li {
  position: relative;
  margin: 0 5px;
  padding: 0 1.6rem;
  background: #d9f0eb;
}

.action-links li a {
  color: #000;
  border-bottom: 0;
}

.action-links li:active, .action-links li:hover {
  background: #98d6c8;
}

.action-links li:active::after, .action-links li:hover::after {
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 100%;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border: solid transparent;
  border-top-color: #98d6c8;
  border-width: 10px;
  margin-left: -10px;
}

@media (min-width: 777px) {
  .action-links {
    display: flex;
    flex-direction: row;
    flex-wrap: none;
    justify-content: flex-end;
  }
  .action-links li:last-child {
    margin-right: 0;
  }
}

[role='main'] .tabs {
  max-width: none;
}

.autocomplete,
.form-autocomplete {
  background-image: url(../sass/forms/autocomplete/throbber-inactive.png);
  background-position: 100% center;
  background-repeat: no-repeat;
}

[dir="rtl"] .autocomplete,
[dir="rtl"] .form-autocomplete {
  background-position: 0% center;
}

.autocomplete__list-wrapper,
#autocomplete {
  border: 1px solid;
  overflow: hidden;
  position: absolute;
  z-index: 100;
}

.autocomplete__list,
#autocomplete ul {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
}

.autocomplete__list-item,
#autocomplete li {
  background: #fff;
  color: #000;
  cursor: default;
  white-space: pre;
}

.autocomplete__list-item.is-selected, .autocomplete__list-item--is-selected,
#autocomplete li.is-selected,
#autocomplete .selected {
  background: #39c6f4;
  color: #fff;
}

.autocomplete.is-throbbing, .autocomplete--is-throbbing,
.is-throbbing.form-autocomplete,
.form-autocomplete.throbbing {
  background-image: url(../sass/forms/autocomplete/throbber-active.gif);
}

.collapsible-fieldset,
.collapsible {
  position: relative;
}

.collapsible-fieldset__legend,
.collapsible .fieldset-legend {
  display: block;
  padding-left: 15px;
  background-image: url(../sass/forms/collapsible-fieldset/expanded.svg);
  background-position: 4px 50%;
  background-repeat: no-repeat;
}

[dir="rtl"] .collapsible-fieldset__legend,
[dir="rtl"] .collapsible .fieldset-legend,
.collapsible [dir="rtl"] .fieldset-legend {
  padding-left: 0;
  padding-right: 15px;
  background-position: right 4px top 50%;
}

.collapsible-fieldset__summary,
.collapsible .fieldset-legend .summary {
  color: #999999;
  font-size: .9em;
  margin-left: .5em;
}

.collapsible-fieldset.is-collapsed, .collapsible-fieldset--is-collapsed,
.is-collapsed.collapsible,
.collapsible.collapsed {
  border-bottom-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  height: 1em;
}

.collapsible-fieldset.is-collapsed .collapsible-fieldset__wrapper, .collapsible-fieldset--is-collapsed__wrapper, .is-collapsed.collapsible .collapsible-fieldset__wrapper,
.collapsible.collapsed .fieldset-wrapper {
  display: none;
}

.collapsible-fieldset.is-collapsed .collapsible-fieldset__legend, .collapsible-fieldset--is-collapsed__legend, .is-collapsed.collapsible .collapsible-fieldset__legend,
.collapsible.collapsed .fieldset-legend,
.collapsible-fieldset.is-collapsed .collapsible .fieldset-legend,
.collapsible .collapsible-fieldset.is-collapsed .fieldset-legend,
.is-collapsed.collapsible .fieldset-legend {
  background-image: url(../sass/forms/collapsible-fieldset/collapsed.svg);
  background-position: 4px 50%;
}

[dir="rtl"] .collapsible-fieldset.is-collapsed .collapsible-fieldset__legend, [dir="rtl"] .collapsible-fieldset--is-collapsed__legend, [dir="rtl"] .is-collapsed.collapsible .collapsible-fieldset__legend,
[dir="rtl"] .collapsible.collapsed .fieldset-legend,
.collapsible.collapsed [dir="rtl"] .fieldset-legend,
[dir="rtl"] .collapsible-fieldset.is-collapsed .collapsible .fieldset-legend,
.collapsible [dir="rtl"] .collapsible-fieldset.is-collapsed .fieldset-legend,
[dir="rtl"] .is-collapsed.collapsible .fieldset-legend {
  background-image: url(../sass/forms/collapsible-fieldset/collapsed-rtl.svg);
  background-position: right 4px top 50%;
}

.form-actions {
  text-align: right;
}

.form-actions button,
.form-actions input[type="submit"] {
  margin-left: 1.6rem;
}

.form-item {
  margin: 1.6rem 0;
}

.form-item__required,
.form-required {
  color: #e93125;
}

.form-item .form-type-textfield {
  margin: 0;
}

.form-item__description,
.form-item .description {
  font-size: 0.93333rem;
  color: #999999;
  margin-top: 0.4rem;
  font-size: 80%;
  line-height: 1.6;
}

.form-item--inline div,
.form-item--inline label, .container-inline .form-item div, .container-inline .form-item label {
  display: inline;
}

.form-item--inline__exception,
.container-inline .fieldset-wrapper {
  display: block;
}

.form-item--tight,
.form-item--radio,
.form-type-radio,
.form-type-checkbox,
.password-parent,
.confirm-parent,
table .form-item {
  margin: 0;
}

.form-item--radio .form-item__label, .form-item--radio__label, .form-type-radio .form-item__label,
.form-type-checkbox .form-item__label,
label.option {
  display: inline;
  font-weight: normal;
}

.form-item--radio .form-item__description, .form-item--radio__description, .form-type-radio .form-item__description,
.form-type-checkbox .form-item__description,
.form-type-radio .description,
.form-type-checkbox .description,
.form-item--radio .form-item .description,
.form-item .form-item--radio .description,
.form-type-radio .form-item .description,
.form-item .form-type-radio .description,
.form-type-checkbox .form-item .description,
.form-item
.form-type-checkbox .description {
  margin-left: 1.4em;
}

.form-item.is-error .form-item__widget, .form-item--is-error__widget,
.form-item.is-error input,
.form-item.is-error textarea,
.form-item.is-error select,
.form-item--is-error input,
.form-item--is-error textarea,
.form-item--is-error select,
.form-item input.error,
.form-item textarea.error,
.form-item select.error {
  border: 1px solid #e93125;
}

.form-table__sticky-header,
.sticky-header {
  position: fixed;
  visibility: hidden;
  margin-top: 0;
  background-color: #fff;
}

.form-table__sticky-header.is-sticky, .form-table__sticky-header--is-sticky,
.is-sticky.sticky-header {
  visibility: visible;
}

.form-table__header,
.form-table th,
form table th {
  border-bottom: 3px solid #cccccc;
  padding-right: 1em;
  text-align: left;
}

[dir="rtl"] .form-table__header,
[dir="rtl"] .form-table th,
.form-table [dir="rtl"] th,
[dir="rtl"] form table th,
form table [dir="rtl"] th {
  text-align: right;
  padding-left: 1em;
  padding-right: 0;
}

.form-table__body,
.form-table tbody,
form table tbody {
  border-top: 1px solid #cccccc;
}

.form-table__row,
.form-table tbody tr,
form table tbody tr {
  padding: .1em .6em;
  border-bottom: 1px solid #cccccc;
  background-color: #eeeeee;
}

.form-table__row:nth-child(even),
.form-table tbody tr:nth-child(even),
form table tbody tr:nth-child(even) {
  background-color: #fff;
}

.form-table__row.is-active, .form-table__row--is-active,
.form-table tbody tr.is-active,
form table tbody tr.is-active,
td.active {
  background-color: #dddddd;
}

.form-table__row.is-disabled, .form-table__row--is-disabled,
.form-table tbody tr.is-disabled,
form table tbody tr.is-disabled,
td.menu-disabled {
  background: #cccccc;
}

.form-table__row.is-selected, .form-table__row--is-selected,
.form-table tbody tr.is-selected,
form table tbody tr.is-selected,
tr.selected td {
  background: white;
}

.form-table__list,
.form-table ul,
form table ul {
  margin: 0;
}

.form-table__narrow-column,
.form-table th.form-table__narrow-column,
form table th.form-table__narrow-column,
td .checkbox,
th .checkbox {
  width: -moz-min-content;
  width: -webkit-min-content;
  text-align: center;
}

.progress-bar,
.progress {
  font-weight: bold;
}

.progress-bar__bar,
.progress .bar {
  border-radius: 3px;
  margin: 0 .2em;
  border: 1px solid #666666;
  background-color: #cccccc;
}

.progress-bar__fill,
.progress .filled {
  height: 1.5em;
  width: 5px;
  background: #39c6f4 url(../sass/forms/progress-bar/progress-bar.gif) repeat 0 0;
}

.progress-bar__percentage,
.progress .percentage {
  float: right;
}

[dir="rtl"] .progress-bar__percentage,
[dir="rtl"] .progress .percentage,
.progress [dir="rtl"] .percentage {
  float: left;
}

.progress-bar--inline,
.ajax-progress-bar {
  width: 16em;
  display: inline-block;
}

[dir="rtl"] .progress-bar--inline,
[dir="rtl"] .ajax-progress-bar {
  float: right;
}

.progress-throbber,
.ajax-progress {
  display: inline-block;
}

[dir="rtl"] .progress-throbber,
[dir="rtl"] .ajax-progress {
  float: right;
}

.progress-throbber__widget,
.ajax-progress .throbber {
  background: url(../sass/forms/progress-throbber/progress-throbber.gif) no-repeat 0 -18px transparent;
  float: left;
  height: 15px;
  margin: 2px;
  width: 15px;
}

[dir="rtl"] .progress-throbber__widget,
[dir="rtl"] .ajax-progress .throbber,
.ajax-progress [dir="rtl"] .throbber {
  float: right;
}

.progress-throbber__widget-in-tr,
tr .ajax-progress .throbber {
  margin: 0 2px;
}

.progress-throbber__message,
.ajax-progress .message {
  padding-left: 20px;
}

.resizable-textarea,
.resizable-textarea textarea {
  width: 100%;
  vertical-align: bottom;
}

.resizable-textarea__grippie,
.resizable-textarea .grippie {
  background: url(../sass/forms/resizable-textarea/grippie.png) no-repeat center 2px #eeeeee;
  border: 1px solid #cccccc;
  border-top-width: 0;
  cursor: s-resize;
  height: 9px;
  overflow: hidden;
}

.table-drag__wrapper,
body.drag {
  cursor: move;
}

.table-drag__item,
tr.drag {
  background-color: white;
}

.table-drag__item-previous,
tr.drag-previous {
  background-color: #f2f2f2;
}

.table-drag__handle,
.tabledrag-handle {
  cursor: move;
  float: left;
  height: 1.7em;
  margin-left: -1em;
  overflow: hidden;
  text-decoration: none;
  font-size: 12px;
}

[dir="rtl"] .table-drag__handle,
[dir="rtl"] .tabledrag-handle {
  float: right;
  margin-right: -1em;
  margin-left: 0;
}

.table-drag__handle:focus, .table-drag__handle:hover,
.tabledrag-handle:focus,
.tabledrag-handle:hover {
  text-decoration: none;
}

.table-drag__handle-icon,
.tabledrag-handle .handle {
  box-sizing: content-box;
  background: url(../sass/forms/table-drag/handle-icon.png) no-repeat 6px 9px;
  height: 13px;
  margin: -.4em .5em;
  padding: .42em .5em;
  width: 13px;
}

.table-drag__handle.is-hover .table-drag__handle-icon, .table-drag__handle-icon--is-hover, .is-hover.tabledrag-handle .table-drag__handle-icon,
.tabledrag-handle-hover .handle,
.table-drag__handle.is-hover .tabledrag-handle .handle,
.tabledrag-handle .table-drag__handle.is-hover .handle,
.is-hover.tabledrag-handle .handle {
  background-position: 6px -11px;
}

.table-drag__toggle-weight-wrapper,
.tabledrag-toggle-weight-wrapper {
  text-align: right;
}

[dir="rtl"] .table-drag__toggle-weight-wrapper,
[dir="rtl"] .tabledrag-toggle-weight-wrapper {
  text-align: left;
}

.table-drag__toggle-weight,
.tabledrag-toggle-weight {
  font-size: .9em;
}

.table-drag__indentation,
.indentation {
  float: left;
  height: 1.7em;
  margin: -.4em .2em -.4em -.4em;
  padding: .42em 0 .42em .6em;
  width: 20px;
}

[dir="rtl"] .table-drag__indentation,
[dir="rtl"] .indentation {
  float: right;
  margin: -.4em -.4em -.4em .2em;
  padding: .42em .6em .42em 0;
}

.table-drag__tree-child,
.table-drag__tree-child-last,
.tree-child-last,
.table-drag__tree-child-horizontal,
.tree-child-horizontal,
.tree-child {
  background: url(../sass/forms/table-drag/tree-child.png) no-repeat 11px center;
}

[dir="rtl"] .table-drag__tree-child,
[dir="rtl"] .table-drag__tree-child-last,
[dir="rtl"] .tree-child-last,
[dir="rtl"] .table-drag__tree-child-horizontal,
[dir="rtl"] .tree-child-horizontal,
[dir="rtl"] .tree-child {
  background-position: -65px center;
}

.table-drag__tree-child-last,
.tree-child-last {
  background-image: url(../sass/forms/table-drag/tree-child-last.png);
}

.table-drag__tree-child-horizontal,
.tree-child-horizontal {
  background-position: -11px center;
}
