// Photocolleciton
//
// Styling for a lead photo-collection
//

.photo-collection,
%photo-collection {
  background-color: color(black);
  font-weight: 200;
  color: color(white);
  padding-bottom: 10px;

  p {
    line-height: 1.5;
    max-width: 65em;
  }

  p:first-child {
    margin-top: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }

  &__header {
    padding: 15px 10px;

    h1,
    h2,
    h4 {
      font-weight: 200;
      line-height: 1.2;
      padding: 0;
      margin: 0;
    }

    h1 {

      a[href],
      a:link,
      a:visited {
        border-width: 0;
      }
    }

    h2,
    h4 {
      color: color('grey');
    }

    a:link,
    a:visited {
      color: inherit;
      border-bottom-color: color('grey');
    }
  }

  .field-type-link-field {
    max-width: 100%;
    overflow: hidden;
    text-overflow: elipsis;

  }


  &__photos {
    align-content: center;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-around;
    overflow: auto;
    max-width: 100%;

    figure {
      margin: 0;
      box-sizing: border-box;
      padding: 0 5px;
      position: relative;
      overflow: hidden;

      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      margin: .8rem 0;

      img {
        max-width: 30vw;
      }

      figcaption {
        font-size: 75%;
        padding: 10px 5px;
        box-sizing: border-box;
        position: absolute;
        bottom: -20px;
        background-color: rgba(0, 0, 0, .5);
        max-height: 0;
        overflow: hidden;
        transition: max-height .4s, bottom .4s;
        left: 0;
        right: 0;

        line-height: 1.5;
      }

    }

    figure:hover,
    figure:active {
      figcaption {
        max-height: 100%;
        bottom: 0;
      }
    }

  }

    &__footer {
      padding: 10px 10px 0;
      font-size: 80%;
      color: color('grey');

      p:last-of-type {
        margin-bottom: 0;
      }
    }

}
