// Headings
//
// All HTML headings, `<h1>` through `<h6>`, are available. `%h1` through `%h6`
// classes are also available, for when you want to match the font styling of a
// heading but still want your text to be displayed inline.
//
// Markup: headings-h.twig
//
// Style guide: base.headings

%heading {
  font-weight: 200;
}

// Address variable `h1` font-size and margin within `section` and `article`
// contexts in Firefox 4+, Safari, and Chrome.
h1,
%h1 {
  @extend %heading;
  // Set the font-size and line-height while keeping a proper vertical rhythm.
  @include type-layout(xxl, 1.5);

  // Set 1 unit of vertical rhythm on the top and bottom margins.
  @include margin-top(0, xxl);
  @include margin-bottom(.5, xxl);
}

h2,
%h2 {
  @extend %heading;
  @include type-layout(xl, 1.4);
  @include margin-top(1, xl);
  @include margin-bottom(.4, xl);
}

h3,
%h3 {
  @extend %heading;
  @include type-layout(l, 1.4);
  @include margin-top(1, l);
  @include margin-bottom(.2, l);
}

h4,
%h4 {
  @extend %heading;
  @include type-layout(m, 1.2);
  @include margin-top(1, m);
  @include margin-bottom(.1, m);
  font-weight: bold;
}

h5,
%h5 {
  @extend %heading;
  @include type-layout(m, 1.2);
  @include margin-top(1, s);
  @include margin-bottom(.1, s);
  font-style: italic;
}

h6,
%h6 {
  @extend %heading;
  @include type-layout(m, 1.2);
  @include margin-top(1, xs);
  @include margin-bottom(.1, xs);
}
