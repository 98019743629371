

.main .node {

  padding-bottom: .8rem;

  header a:link,
  header a:visited {
    color: color(text);
    border-bottom: 0;
  }

  .field-type-datetime {
    max-width: 50rem;
    margin: 0 auto 2rem;
    color: color(article-date);
  }



  .field-name-field-lead-image {
    @include padding(1);
    @include margin-bottom(1);

    text-align: center;
    background-color: #000;
    margin-left: -10px;
    margin-right: -10px;
    overflow: hidden;

    @include zen-respond-to(m) {
      margin-left: 0;
      margin-right: 0;
    }

    .field-item {
      float: none;
      width: auto;
      margin: 0 auto;
      clear: both;
    }

    figure,
    img {
      padding: 0;
      margin: 0;
    }

    figcaption {
      text-align: right;
      color: color(grey-light);
      font-size: 80%;
      line-height: 1.3;
      margin: 0 auto;
    }
  }

  &:last-child {
    border-bottom: 0;
    margin-bottom: 0;
  }

  &-teaser {
    h2 {
      margin-top: 0;
    }
  }
}
