// Dependencies.
@import 'components/clearfix/clearfix';

// Tabs
//
// The primary and secondary tabs.
//
// Markup: tabs.twig
//
// .tabs--secondary - Secondary tabs
//
// Style guide: navigation.tabs

// Tabs (stacked)
//
// When the secondary tabs appear directly after the primary tabs, the styling
// is slightly different.
//
// Markup: tabs--stacked.twig
//
// Style guide: navigation.tabs.stacked

.action-links,
%action-links {
  @extend %clearfix;

  // The line height of a tab.
  $tab-height: 1.8;
  // @include margin(0);
  @include line-height($tab-height);

  position: relative;
  padding: 0 2px;
  list-style: none;
  white-space: nowrap;
  margin-top: -20px;
  margin-bottom: 20px;
  max-width: none;
  left: -10px;
  width: calc(100% + 20px);
  background-color: color(tabs-bg);
  overflow: visible;


  @media print {
    display: none;
  }

  li {
    position: relative;
    margin: 0 5px;
    padding: 0 1.6rem;
    background: color(tab-bg);

    a {
      color: color(tab);
      border-bottom: 0;
    }

    &:active,
    &:hover {
      background: color(nav-highlight);
    }

    &:active::after,
    &:hover::after {
      position: absolute;
      z-index: 1;
      left: 50%;
      top: 100%;
      content: ' ';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border: solid transparent;
      border-top-color: color(nav-highlight);
      border-width: 10px;
      margin-left: -10px;
    }
  }

  @include zen-respond-to(m) {
    display: flex;
    flex-direction: row;
    flex-wrap: none;
    justify-content: flex-end;

    li:last-child {
      margin-right: 0;
    }

  }

}

[role='main'] .tabs {
  max-width: none;
}

//
// Drupal selectors.
//

// Views mis-uses the theme hooks for tabs.
.views-displays .secondary {
  @extend %tabs--off;
}
