// Pager
//
// Paged navigation is a list of page numbers when more than 1 page of content
// is available.
//
// Markup: pager.twig
//
// Style guide: navigation.pager

.pager,
%pager {
  clear: both;
  padding: 0;
  text-align: center;
  background-color: color('tabs-bg');

  @media print {
    display: none;
  }

  // A page item in the pager list.
  &__item {
    display: inline-block;
    padding: 0.3rem 0.6rem;
    list-style-type: none;
    background-image: none;
    margin: 0 0.15rem;

    a:link,
    a:visited,
    a:hover,
    a:active {
      border: 0;
      color: color('text');
    }
    position: relative;
  }

  // The current page's list item.
  &__current-item {
    @extend %pager__item;
    font-weight: bold;
    background-color: color('sub-nav-highlight');

    &:after {
      position: absolute;
      z-index: 1;
      left: 50%;
      bottom: 100%;
      content: ' ';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border: solid transparent;
      border-bottom-color: color('sub-nav-highlight');
      border-width: 8px;
      margin-left: -8px;
    }
  }
}

//
// Drupal selectors.
//

// .pager-item     - A list item containing a page number in the list of pages.
// .pager-first    - The first page's list item.
// .pager-previous - The previous page's list item.
// .pager-next     - The next page's list item.
// .pager-last     - The last page's list item.
// .pager-ellipsis - A concatenation of several list items using an ellipsis.

.pager-item,
.pager-first,
.pager-previous,
.pager-next,
.pager-last,
.pager-ellipsis {
  @extend %pager__item;
}

.pager-current {
  @extend %pager__current-item;
}

.main .pager {
  max-width: none;
  margin-left: -10px;
  margin-right: -10px;
}
