// sass-lint:disable indentation, no-color-keywords

// Colors
//
// Use the `color()` function to add colors to CSS properties. To learn more,
// [read the Chroma documentation](http://johnalbin.github.io/chroma/).
//
// Markup: chroma.twig
//
// Weight: -1
//
// Style guide: sass.colors

// Define the default color scheme's color names.
$chroma: define-default-color-scheme('branding', 'The site\'s main colors. Can be used to define colors in other color schemes.');

$chroma: add-colors((
  black:                  #000,
  grey-dark:              ('black' lighten 40%), // #666
  'grey':                 ('black' lighten 60%), // #999
  grey-light:             ('black' lighten 80%), // #ccc
  grey-extra-light:       ('black' lighten 93.33%), // #eee
  white:                  #fff,

  blue:                   #39c6f4,
  red:                    #e93125,
  yellow:                 #f8c746,
  brown:                  #928273,
  lilac:                  #8a83b9,
  green:                  #84c556,
  pink:                   #efe4df,
  turquoise:              #82cdbc,

  // Colors used in the main content area.
  text:                   'black',
  text-bg:                'white',
  article-date:           ('text' lighten 60%),

  link:                   'blue',
  link-visited:           ('blue' darken 20%),
  link-active:            'green',
  link-light:             ('blue' lighten 20%),

  border:                 'grey-light',

  autocomplete:           'text',
  autocomplete-bg:        'text-bg',
  autocomplete-select:    'white',
  autocomplete-select-bg: 'blue',
  body-bg:                'text-bg',

  fieldset-summary:       'grey',
  form-error:             'red',

  mark-highlight:         'red',
  mark-bg:                'yellow',

  menu-active:            'text',

  preview-bg:             ('yellow' lighten 43%),

  progress-bar:           'blue',
  progress-bar-bg:        'grey-light',
  progress-bar-border:    'grey-dark',

  resizable-grippie:      'grey-extra-light',

  row-stripe:             'grey-extra-light',
  row-disabled:           'grey-light',

  skip-link:              'white',
  skip-link-bg:           'grey-dark',

  status:                 'blue',
  status-bg:              (status lighten 62%),
  status-highlight:       text,
  warning:                text,
  warning-bg:             ('yellow' lighten 45%),
  warning-border:         'yellow',
  error:                  'red',
  error-bg:               (error lighten 57%),

  table-drag:             ('yellow' lighten 43%),
  table-select:           ('yellow' lighten 47%),

  watermark:              'grey-extra-light',

));

// Define color names for functional uses.
$chroma: define-color-scheme('functional', 'Colors used by functional parts of the design.');
$chroma: define-color-scheme('organisation', 'Colors used by functional parts of the design.');
$chroma: define-color-scheme('activity', 'Colors used by functional parts of the design.');
$chroma: define-color-scheme('members', 'Colors used by functional parts of the design.');

$chroma: add-colors('functional', (
  button:                 'black',
  button-disabled:        ('button' lighten 40%),
  button-active:           ('button' darken 10%),

  button-bg:              ('brown' lighten 20%),
  button-bg-disabled:     ('button-bg' lighten 40%),
  button-bg-active:       ('button-bg' darken 10%),
  button-border-highlight:('button-bg' lighten 15%),
  button-border-shadow:   ('button-bg' darken 15%),

  nav-bg:                 ('brown'),
  nav-highlight:          ('nav-bg' lighten 6%),
  sub-nav-bg:             ('nav-bg' lighten 12%),
  sub-nav-highlight:      ('nav-bg' lighten 18%),

  tabs-bg:                ('nav-bg' lighten 24%),
  tabs-border:            'tabs-bg',
  tab:                    'text',
  tab-bg:                 'tabs-bg',
  tab-highlight:          ('nav-bg' lighten 30%),
  tab-secondary:          'sub-nav-bg',

  rule:                   ('nav-bg' lighten 20%),

  footer-bg:              ('nav-bg' lighten 24%),
  footer-text:            ('text' lighten 24%),
));

$chroma: add-colors('organisation', (
  button:                 'black',
  button-disabled:        ('button' lighten 40%),
  button-active:           ('button' darken 10%),

  button-bg:              ('turquoise' lighten 20%),
  button-bg-disabled:     ('button-bg' lighten 40%),
  button-bg-active:       ('button-bg' darken 10%),
  button-border-highlight:('button-bg' lighten 15%),
  button-border-shadow:   ('button-bg' darken 15%),

  nav-bg:                 ('turquoise'),
  nav-highlight:          ('nav-bg' lighten 6%),
  sub-nav-bg:             ('nav-bg' lighten 12%),
  sub-nav-highlight:      ('nav-bg' lighten 18%),

  tabs-bg:                ('nav-bg' lighten 24%),
  tabs-border:            'tabs-bg',
  tab:                    'text',
  tab-bg:                 'tabs-bg',
  tab-highlight:          ('nav-bg' lighten 30%),
  tab-secondary:          'sub-nav-bg',

  rule:                   ('nav-bg' lighten 20%),

  footer-bg:              ('nav-bg' lighten 24%),
  footer-text:            ('text' lighten 24%),
));

$chroma: add-colors('activity', (
  button:                 'black',
  button-disabled:        ('button' lighten 40%),
  button-active:           ('button' darken 10%),

  button-bg:              ('green' lighten 20%),
  button-bg-disabled:     ('button-bg' lighten 40%),
  button-bg-active:       ('button-bg' darken 10%),
  button-border-highlight:('button-bg' lighten 15%),
  button-border-shadow:   ('button-bg' darken 15%),

  nav-bg:                 ('green'),
  nav-highlight:          ('nav-bg' lighten 6%),
  sub-nav-bg:             ('nav-bg' lighten 12%),
  sub-nav-highlight:      ('nav-bg' lighten 18%),

  tabs-bg:                ('nav-bg' lighten 24%),
  tabs-border:            'tabs-bg',
  tab:                    'text',
  tab-bg:                 'tabs-bg',
  tab-highlight:          ('nav-bg' lighten 30%),
  tab-secondary:          'sub-nav-bg',

  rule:                   ('nav-bg' lighten 20%),

  footer-bg:              ('nav-bg' lighten 24%),
  footer-text:            ('text' lighten 24%),
));

$chroma: add-colors('activity', (
  button:                 'black',
  button-disabled:        ('button' lighten 40%),
  button-active:           ('button' darken 10%),

  button-bg:              ('blue' lighten 20%),
  button-bg-disabled:     ('button-bg' lighten 40%),
  button-bg-active:       ('button-bg' darken 10%),
  button-border-highlight:('button-bg' lighten 15%),
  button-border-shadow:   ('button-bg' darken 15%),

  nav-bg:                 ('blue'),
  nav-highlight:          ('nav-bg' lighten 6%),
  sub-nav-bg:             ('nav-bg' lighten 12%),
  sub-nav-highlight:      ('nav-bg' lighten 18%),

  tabs-bg:                ('nav-bg' lighten 24%),
  tabs-border:            'tabs-bg',
  tab:                    'text',
  tab-bg:                 'tabs-bg',
  tab-highlight:          ('nav-bg' lighten 30%),
  tab-secondary:          'sub-nav-bg',

  rule:                   ('nav-bg' lighten 20%),

  footer-bg:              ('nav-bg' lighten 24%),
  footer-text:            ('text' lighten 24%),
));

$chroma-active-scheme: 'organisation';
