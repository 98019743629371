// Site wide login box
//
// The main site navigation hierarchial menu
//

.menu-name-user-menu,
.block-user-login {

  padding: 1rem 0;

  .user--login--title {
    display: none;
  }

  .form-item,
  .item-list,
  .form-actions {
    float: left;
    margin: 0 0 0 1rem;

    .description {
      font-size: 70%;
    }

    [type='submit'] {
      padding: 0 10px;
      font-size: 80%;
      margin: 0;
      height: 1.7rem;
    }

    a:link,
    a:visited {
      color: color('grey');
      border-color: color('grey');
      border-bottom-width: 0;
    }

    a:active,
    a:hover {
      color: color('link-active');
      border-color: color('link-active');

    }

  }

  label {
    font-size: 80%;
  }

  .menu {
    background: none;

    .menu__item {
      color: color('grey');
       a {
        color: inherit;
        padding: 0 15px;
        line-height: 2.4rem;

        &:link,
        &:visited {
          color: inherit;
        }

        &:hover {
          color: black;
        }
       }

       .username {
         color: black;
       }
    }
  }


  @include zen-respond-to(m) {

    padding: 0 1rem;
    margin-left: 300px;

  }

}
