// System Main menu
//
// The main site navigation hierarchial menu
//

.block-system-main-menu--wrapper {
  background-color: color(nav-bg);
}

.block-system-main-menu {
  position: relative;
  clear: both;

  .menu {
    background-color: color(nav-bg);
    margin: 0;
    padding-left: 0;
    position: relative;
    width: 100%;

    .menu__item {
      font-weight: 200;
      list-style: none;

      a {
        position: relative;
        color: black;
        display: block;
        padding: 5px 15px;
        text-decoration: none;
        border-bottom: 0;
        white-space: nowrap;
      }

      a:link,
      a:visited {
        color: black;
      }

      a.is-active-trail::after {
        position: absolute;
        z-index: 1;
        left: 50%;
        top: 100%;
        content: ' ';
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border: solid transparent;
        border-top-color: color(nav-highlight);
        border-width: 10px;
        margin-left: -10px;
      }

    }


    .menu {
      background-color: color(sub-nav-bg);


      .menu__item {
        width: 100%;
        text-indent: 10px;

        a.is-active-trail {
          background-color: color(sub-nav-highlight);
        }

        a.is-active-trail::after {
          border-top-color: color(sub-nav-highlight);
        }
      }

      a:hover {
        background-color: color(sub-nav-highlight);
      }


    }

    .is-active-trail {
        background-color: color(nav-highlight);
    }



  }

  @include zen-respond-to(m) {


    .menu {
      display: flex;
      flex-direction: row;
      flex-wrap: none;
      justify-content: space-between;

      .menu__item {
        display: block;
        text-align: center;
        width: auto;

        a {
          padding: 10px 15px;
        }

      }

      .menu {
        left: 0;
        position: absolute;
        top: 44px;
        width: 100%;

        .menu__item {
          display: block;
          text-align: center;
          width: 100%;
        }

        a:hover {
          background-color: color(sub-nav-highlight);
        }

        a.is-active-trail {
          background-color: color(sub-nav-highlight);
        }

        a.is-active-trail::after {
          border-top-color: color(sub-nav-highlight);
        }

      }
    }

    .is-expanded {
      margin-bottom: 44px;
    }

    a:hover {
      background-color: color(nav-highlight);
    }

    a.is-active-trail {
        background-color: color(nav-highlight);
        position: relative;
        color: color(white);
    }

    a.is-active-trail::after {
          position: absolute;
          z-index: 1;
          left: 50%;
          top: 100%;
          content: ' ';
          height: 0;
        	width: 0;
        	position: absolute;
        	pointer-events: none;
          border: solid transparent;
        	border-top-color: color(nav-highlight);
        	border-width: 10px;
        	margin-left: -10px;
    }

  }
}
