
.field-group-multipage {

  .multipage {
    &-counter {
      display: block;
      text-align: right;
      clear: both;
      float: none;
      margin: 0.6rem 0;
      top: auto;
      height: auto;
      font: inherit;
      font-size: 120%;

      &:before {
        content: 'Step: '
      }

      em {
        font-style: normal;
      }
    }

    &-controls-list {
      text-align: right;

      .multipage-button {
        float: none;
        font: inherit;


      }

      input.form-submit {
        margin-left: 0.6rem;
        border-radius: 0;
        border-width: 1px;
        font-weight: 300;
        font-size: 1rem;
        font-family: 'Neue Helvetica', helvetica, arial, sans-serif;
        border: 1px solid color('button-border-shadow');
        border-color: color('button-border-highlight') color('button-border-shadow') color('button-border-shadow') color('button-border-highlight');
        background: color('button-bg');
        text-shadow: none;
      }

      input.form-submit:hover,
      input.form-submit:active {
        border: 1px solid color('button-border-shadow');
        border-color: color('button-border-shadow') color('button-border-highlight') color('button-border-highlight') color('button-border-shadow');
        background: color('button-bg-active');
      }

      .form-actions {
        float: right;
      }

      #edit-preview {
        display: none;
      }
    }
  }

}
