// Dependencies.
@import 'components/clearfix/clearfix';

// Tabs
//
// The primary and secondary tabs.
//
// Markup: tabs.twig
//
// .tabs--secondary - Secondary tabs
//
// Style guide: navigation.tabs

// Tabs (stacked)
//
// When the secondary tabs appear directly after the primary tabs, the styling
// is slightly different.
//
// Markup: tabs--stacked.twig
//
// Style guide: navigation.tabs.stacked

.tabs,
%tabs {
  @extend %clearfix;

  // The line height of a tab.
  $tab-height: 1.8;
  // @include margin(0);
  @include line-height($tab-height);

  position: relative;
  padding: 0 2px;
  list-style: none;
  white-space: nowrap;
  margin-top: -5px;
  margin-bottom: 20px;
  max-width: none;
  left: -10px;
  width: calc(100% + 20px);
  background-color: color(tabs-bg);
  overflow: visible;

  @include zen-respond-to(m) {
    margin-top: -20px;
  }

  @media print {
    display: none;
  }

  &__tab {
    position: relative;
    margin: 0 5px;
    background: color(tab-bg);
    padding: 0 1.6rem;

    a {
      color: color(tab);
      border-bottom: 0;
    }

    &:active,
    &.is-active,
    &--is-active {
      background: color(tab-highlight);
    }

    &.is-active::after {
      position: absolute;
      z-index: 1;
      left: 50%;
      top: 100%;
      content: ' ';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border: solid transparent;
      border-top-color: color(tab-highlight);
      border-width: 10px;
      margin-left: -10px;
    }
  }

  &__tab-link {
    @include padding(0 1);
    display: block;
    text-decoration: none;
    position: relative;

    color: color(tab);
    background: color(tabs-bg);
    letter-spacing: 1px;

    &:link,
    &:visited {
      color: color(tab);
    }

    &:focus,
    &:hover {
      background: color(tab-highlight);
    }

    &:active,
    &.is-active,
    &--is-active {
      background: color(tab-highlight);
    }
  }

  // Turns off .tabs styling.
  &--off {
    @include margin(0);
    @if support-for(ie, 9) {
      border-bottom: 0;
    }
    padding: 0;
    background-image: none;
  }

  &--secondary {
    @include margin-top(1);
    @include font-size(s);
    background-image: none;
  }

  // When secondary tabs appear directly after primary tabs.
  & + &--secondary {
    @include margin-top(0);
    background-image: linear-gradient(to top, color(tabs-border) 1px, transparent 1px);
  }

  &--secondary &__tab {
    @include margin((2 - $tab-height)/2 3px);
    border: 0;
    background: transparent;

    @include rtl() {
      float: right;
    }

    &.is-active {
      border-bottom-color: transparent;
    }
  }

  &--secondary &__tab-link {
    border: 1px solid color(tabs-border);
    color: color(tab-secondary);
    background: lighten(color(tab-bg), 8%);
    letter-spacing: normal;

    &:focus,
    &:hover {
      color: darken(color(tab-secondary), 20%);
      background: color(tab-bg);
      border-color: darken(color(tab-bg), 26.5%);
    }

    &:active,
    &.is-active,
    &--is-active {
      color: lighten(color(tab-bg), 15%);
      background: color(tab-secondary);
      border-color: darken(color(tab-secondary), 40%);
    }
  }

  @include zen-respond-to(m) {
    display: flex;
    flex-direction: row;
    flex-wrap: none;
    justify-content: space-between;
  }

}

[role='main'] .tabs {
  max-width: none;
}

//
// Drupal selectors.
//

// Views mis-uses the theme hooks for tabs.
.views-displays .secondary {
  @extend %tabs--off;
}
