
.views,
%views {

  &-field {
    max-width: 50rem;
    margin-left: auto;
    margin-right: auto;
  }
}
