.main .node-33 {
  padding: 0;
}

.map,
.main .map {
  margin: -20px 0 -20px;
  width: 100%;
  min-height: 70vh;
  max-height: 100vh;
  height: 80%;

}
