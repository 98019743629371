// Dependencies.
@import 'base/headings/headings';
@import 'components/clearfix/clearfix';

// Branding header
//
// Markup: header.twig
//
// Style guide: components.header

.footer,
%footer {
  @extend %clearfix;

  background-color: color(footer-bg);
  color: color(footer-text);

  padding: 20px 0;

  h1 {
    font-size: 125%;
    margin-bottom: 2px;
    line-height: 1.3;
    text-align: left;
    color: color(footer-text);

    @include zen-respond-to(m) {
      width: auto;
    }
  }

  p {
    font-size: 90%;
  }

  a[href],
  a:link,
  a:visited {
    color: color(footer-text);
    border-bottom: 1px dotted color(footer-text);

    .active {
      font-weight: bold;
    }
  }

  a:hover,
  a:active {
    border-bottom: 1px solid color(footer-text);
    color: black;
  }


  h4 {
    font-size: 140%;
    color: inherit;
  }

  ul.menu {
    padding: 0;
    margin-top: 0;
    list-style: none;
    font-size: 90%;
    margin-right: -.5rem;

    columns: 2;
    column-gap: 1rem;
    color: color(footer-text);

    a[href],
    a:link,
    a:visited {
      border-bottom: 1px solid color(footer-bg);
      color: color(footer-text);

      .active {
        font-weight: bold;
      }
    }

    a:hover,
    a:active {
      border-bottom: 1px solid color(footer-text);
      color: black;
    }


    li {
      list-style: none;
      padding-right: 1rem;
      white-space: nowrap;
      position: relative;

      .is-active-trail,
      .active {
        font-weight: bold;
      }

    }

    ul {
      @include padding-left(1);
      font-size: 100%;
      columns: 1;
    }

  }

  .is-expanded.menu__item {
    list-style: none;
  }

  &__contents {
    width: 100%;
    background-color: inherit;
    position: relative;
    padding: 0;

    .credits {
      text-align: center;
      font-size: 75%;
      color: color(footer-text);
      clear: both;
      margin-bottom: 0;
      color: color('grey');

      a[href],
      a:link,
      a:visited {
        color: color('grey');
        border-color: color(grey-light);
      }

      a:hover {
        color: black;
      }
    }
  }

  &__navigation {
    padding-top: 20px;

    &__title {
      margin-bottom: 0;
    }

    &__list {
      margin-top: 0;
      list-style: none;

      a:link,
      a:visited {
        color: black;
        text-decoration: none;
        border-bottom: 0;
      }

    }
  }

  footer.region-footer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    clear: both;

    @include zen-respond-to(m) {
      flex-wrap: nowrap;
      flex-direction: row;
      align-items: flex-start;
    }
  }

  .block {
    padding: 0 0.6rem;
    margin-bottom: 0;
    min-width: 300px;
    width: 100%;
    max-width: 100%;
    @include zen-respond-to(xxs) {
      width: 100%;
      max-width: 460px;
    }

    @include zen-respond-to(m) {
      width: 100%;
      border-bottom: 0;
    }
  }

  .block:last-child {
    @include zen-respond-to(m) {
      margin-right: 0;
    }
  }

  .block-block-2 {
    @include zen-respond-to(m) {
      flex-shrink: 2;
    }
  }

  .block-views-featured-supporters-block {
    a[href],
    a:link,
    a:visited {
      border-bottom: 0;
      color: #000;
    }

    img {
      width: 100%;
      max-width: 300px;
      min-width: 150px;
      opacity: 0.76;
    }

    a:hover img {
      opacity: 1;
    }

    @include zen-respond-to(m) {
      flex-shrink: 2;
      max-width: 300px;
      min-width: 120px;
    }
  }

  .footer__strapline {
    max-width: 100%;
    text-align: center;
    font-size: 110%;
    border-bottom: 1px solid color('sub-nav-highlight');
  }

  .credits {
    padding: 0 10px;
  }

  .social-media-icons a {
    border: 0;
  }

  .social-media-icon {
    width: 2rem;
    height: 2rem;
    overflow: hidden;
    display: inline-block;
    fill: #3d3d3d;
    border-width: 0;
    margin-right: 0.6em;
  }

  .social-media-icon svg {
    width: 100%;
    height: 100%;
    fill: inherit;
  }

  .social-media-icon:hover,
  .social-media-icon:active {
    fill: color('link-active');
  }

}

.region-footer {
  max-width: 1160px;
  margin: 0 auto;
  border-bottom: 1px solid color('sub-nav-highlight');
  padding-top: 1.2rem;
  margin-bottom: 1.2rem;
}
