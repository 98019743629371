// Nav menu
//
// A "nav menu" is secondary, hierarchical navigation which can be displayed to the
// side or below the main content.
//
// Markup: nav-menu.twig
//
// Style guide: navigation.nav-menu

.cke_editable,
main,
[role='main'],
.main,
%main {
    padding-top: 5px;
    margin: 0 auto;
    max-width: 1160px;

    @include zen-respond-to(m) {
      padding-top: 20px;
    }

    header,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    ul,
    ol,
    blockquote,
    pre,
    form {
        max-width: 50rem;
        margin-left: auto;
        margin-right: auto;

        &:empty {
          display: none;
        }
    }

    blockquote {
      padding-left: 5%;
      padding-right: 5%;
      font-style: italic;
      position: relative;
      font-family: georgia, times, serif;

      i,
      em {
        font-style: normal;
      }

      p {
        position: relative;
        z-index: 2;
      }

    }

    .align_left {
      float: left;
      clear: left;
      width: 100%;
      @include zen-respond-to(s) {
        max-width: 50%;
      }
    }

    .align_right {
      float: right;
      clear: right;
      width: 100%;
      @include zen-respond-to(s) {
        max-width: 50%;
      }
    }

    .align_center {
      clear: both;
      margin-left: auto;
      margin-right: auto;
    }

    .align_justify {
      clear: both;
      width: 100%;
      max-width: 1160px;
      @include margin(0 auto 1 auto);
    }

    .image-field a {
      border: 0;
    }

    img,
    figure {
      @include margin(0 0 1 0);

      img {
        margin: 0;
      }

      figcaption {
        color: grey;
        font-style: italic;

        i,
        em {
          font-style: normal;
        }
      }
    }

    figure img,
    figure video,
    figure audio,
    figure iframe {
      max-width: 100%;
    }

    .node {
      margin-bottom: 2rem;
    }


    @include zen-respond-to(s) {
      img[style='float:left'],
      figure[style='float:left'],
      img.align_left,
      figure.align_left {
        @include margin(0 1 1 0);
        max-width: 50%;
      }

      img[style='float:right'],
      figure[style='float:right'],
      img.align_right,
      figure.align_right {
        @include margin(0 0 1 1);
        max-width: 50%;
      }

      img.align_center,
      figure.align_center {
        max-width: 750px;
        height: auto;
        @include margin(0 auto 1 auto);
      }
    }


    table {
      @include margin(0 auto 1 auto);
      width: auto;
      max-width: 100%;
      border: 1px solid black;


      caption,
      th,
      td {
        padding: 3px 5px;
      }

      caption,
      th {
        font-weight: bold;
      }

    }

    img[src^='//cdn.ckeditor.com'] {
      margin: 0;
      vertical-align: baseline;
      margin-bottom: -.35rem;
    }

    img.file-icon {
      margin: 0;
    }

    h4,
    h5,
    h6 {
      font-size: 1.1em;
    }

    h4 {
      font-weight: bold;
    }

    h5 {
      font-style: italic;
    }

    .field-type-link-field {
      max-width: 100%;
      overflow: hidden;
      text-overflow: elipsis;

    }

}
