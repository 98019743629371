
.membership-prompt {
  border: 4px solid color('rule');
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: 1.6rem;
  margin-top: -10px;
  padding: 0.8rem;
  border-top-width: 0px;
  text-align: center;

  @include zen-respond-to(m) {
    margin-top: -20px;
  }


  p {
    margin-bottom: 0;
  }

  p:first-child {
    font-size: 120%;
  }

  .join-prompt-concessionary {
    color: color('grey');
    margin-top: 0;
  }


}
