// Site wide login box
//
// The main site navigation hierarchial menu
//

.block-search {

  padding-left: 1rem;
  padding-right: 1rem;

  [type='text'] {
    padding-right: 2rem;
    box-sizing: border-box;
  }

  .form-item {
    margin: 0;
  }

  .form-actions {
    position: absolute;
    right: 1em;
    top: 0;
  }

  .form-submit {
    position: relative;
    width: 1.65rem;
    height: 2.2rem;
    min-height: 0;
    padding: 0;
    margin: .1rem .4rem;
    box-sizing: border-box;
    border: 0;
    background-color: #fff;
    background-image: url('/sites/all/themes/pcn/assets/img/search.svg');
    background-position: center center;
    background-repeat: no-repeat;
    text-indent: 1000px;

    &:hover {
      background-image: url('/sites/all/themes/pcn/assets/img/search-hover.svg');
    }
  }

  @include zen-respond-to(m) {

    .form-item,
    .item-list,
    .form-actions {
      float: left;
      margin: 0;
    }

    [type='text'] {
      width: 150px;
    }





    label {
      font-size: 80%;
    }

  }

}
