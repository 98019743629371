// Photocolleciton
//
// Styling for a lead photo-collection
//

.main .block-views-recent-collections-block,
.main .block-views-members-collections-index-block,
.main .view-collections,
.main .collections,
.main %collections {

  &-list-wrapper {
    max-width: none;
    margin: 0 -.5rem 1.6rem;

  }

  .views-row {
    border-bottom: 1px solid color(rule);

    &:last-child {
      border-bottom: 1px solid color(rule);
      margin-bottom: 2rem;
    }
  }

  &-list {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
    max-width: none;
    flex-wrap: wrap;
    max-width: none;

    .views-row {
      width: 25%;
      position: relative;
    }

    &-item {
      min-width: 140px;
      width: 320px;
      max-width: 22%;

      margin: .8rem;
      border-bottom: 1px solid color(rule);

      &:last-child {
        border-bottom: 1px solid color(rule);
        margin-bottom: 2rem;
      }

      .field-content {
        line-height: 1.3;
      }

      .views-field-field-image-main {
        position: relative;
        overflow: hidden;
        margin-bottom: .25rem;

        .field-content {
          position: relative;
          overflow: visible;
          padding-bottom: 66%;
        }

        figure {
          margin: 0;
        }

        a {
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
          overflow: hidden;
          padding: 0;
          z-index: 1;



          img {
            position: relative;
            display: block;
            max-height: 100%;
            height: 100%;
            width: auto;
          }
        }

        figcaption {
          display: none;
        }

      }

      img {
        margin: 0;
      }

      a:link,
      a[href],
      a:visited {
        border: 0;
        padding: 0;
      }
    }
  }
}
.main .form-field-type-image {

  .image-preview {
    float: none;

      img {
        margin-bottom: 0;
      }
  }

  .image-widget-data {
    float: none;

    .filter-wrapper {
      display: none;
    }
  }

  fieldset {
    padding: 0;
    border-left: 0;
    border-right: 0;
  }

  table {
    width: 100%;
    border: 0;


    tbody {
      border-top: 0;
    }

    th {
      font-weight: 300;
      padding-left: 0;
    }

    tr {
      padding: 0;
    }


    td {
      vertical-align: bottom;
      padding: 0.6rem;

      .image-widget-data {
        padding-left: 23px;
      }
    }



    .tabledrag-handle {
      border: 0;
    }
  }
}
