
.comment,
.forum--topic-root {
  max-width: 50rem;
  margin: 0 auto;
  border-bottom: 1px solid color('rule');
  margin-bottom: 1.2rem;
  width: 50rem;
  max-width: 100%;

  .username {
    color: color('grey-dark');
  }

  .submitted {
    font-size: 90%;
    color: color('grey');
    float: left;

    a:link,
    a:visited {
      border-bottom: 0;
    }
  }

  .links,
  .inline.links {
    float: right;
    margin-top: 0;
    text-transform: capitalize;

    li {
      margin-left: 0.8rem;
      padding: 5px 10px;
      border: 1px solid color('sub-nav-bg');
    }

    color: color('grey');


    a:link,
    a:visited,
    a:hover,
    a:active {
      border-bottom: 0;
      color: inherit;
    }

    .comment-reply {
      background-color: color('sub-nav-bg');
      color: color('text');
    }
  }

}

.comment-form {

  &--body-field {
    margin-bottom: 0.8rem;
  }

  &--author {
    text-align: right;
    margin-bottom: 0.8rem;
    div {
      display: inline;
    }
  }
}


.main .forum {

  &--breadcrumbs {
    max-width: 50rem;
    margin: 0 auto;
    a:link,
    a:visited {
      border-bottom: 0;
    }
  }

  &--topic-list {
    width: calc(100% + 20px);
    max-width: none;
    border: 0;
    margin-left: -10px;
    margin-right: -10px;

    th {
      text-align: left;
      font-weight: 200;
      font-size: 130%;
      padding: .8rem .8rem 2.2rem;
      border-bottom: 4px solid color(rule);

      a {
        border-bottom: 0;
        color: color(text);
      }

      img {
        margin: 0 .3rem;
      }
    }

    td {
      padding: .8rem;
      border-bottom: 1px solid color(rule);

      .title {
        font-size: 130%;
      }

      &.icon {
        text-align: center;

        div {
          display: inline-block;
        }
      }

      &.last-reply {
        white-space: normal;
      }
    }
  }


  .indented {
    margin-left: 0;
    padding-left: 2vw;
    background-image: url('/sites/all/themes/pcn/assets/img/forum-response.png');
    background-repeat: no-repeat;
    background-position: top left;
    @include zen-respond-to(m) {
      padding-left: 2.4rem;
      background-position: 10px top;

    }
  }

  .submitted {
    font-size: 90%;
    color: color('grey');
    float: left;
    white-space: normal;
    margin-bottom: 0;

    a:link,
    a:visited {
      border-bottom: 0;
    }
  }


  .new {
    text-transform: uppercase;
  }



  .forum--topic {
    &-root {
      border-bottom: 1px solid color('rule');
    }

    &-foot {
      max-width: 50rem;
      margin: 0 auto;
      overflow: hidden;
    }

    &-reply-thread {
      max-width: 50rem;
      margin: 0 auto;

    }
  }

}
