// Watermark
//
// Make the element appear beneath sibling elements, like a watermark.
//
// Markup: watermark.twig
//
// Style guide: components.watermark

.watermark,
%watermark {
  font-weight: bold;
  text-transform: uppercase;
  display: block;
  height: 0;
  overflow: visible;
  // Remove background highlighting from <mark> in normalize.
  background-color: transparent;
  color: color(watermark);
  font-size: 75px;
  line-height: 1;
  text-align: center;
  // sass-lint:disable no-color-literals
  text-shadow: 0 0 1px rgba(0, 0, 0, .1);
  word-wrap: break-word;
  position: absolute;
  z-index: -1;
  text-align: center;
  width: 50rem;
  max-width: 100%;
}
