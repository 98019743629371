
.newsletter-signup, .block-pcnx-newsletter-signup {
  border-top: 4px solid color('footer-bg');
  margin-left: -10px;
  margin-right: -10px;
  margin-top: 1.6rem;
  padding: 0.8rem;
  border-bottom-width: 0px;
  text-align: center;


  p:first-child {
    margin-bottom: 0.3rem;
  }

  fieldset {
    border: none;
    padding: 0;
    margin: 0;
  }


  .form-item {
    margin: 0;
  }

  &--form, .fieldset-wrapper {
    max-width: 25rem;
    margin: 0 auto 0.3rem;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &--email, .form-item-email {
      display: inline-block;
      margin-right: 1.6rem;
      font-size: 1rem;
      box-sizing: border-box;
      height: 2.4rem;
    }

    &--button, .form-submit {
      display: inline-block;
      margin: 0;
      font-size: 1rem;
      padding: 0 1.6rem;
      box-sizing: border-box;
      height: 2.4rem;

    }
  }

}
