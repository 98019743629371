
.main .block-views-supporters-block,
.main .block-views-supporters-level-2-block {
  border-top-width: 0;
  padding-top: 0;

  p {
    margin-bottom: 0;
  }
}

.view-supporters,
.view-supporters-level-2 {
   max-width: 100%;

   .view-content {
     display: flex;
     justify-content: center;
     flex-wrap: wrap;

     .views-row {
       display: inline-block;
       box-sizing: border-box;
       padding: 1.2rem;
       border-bottom: 0;

       min-width: 320px;

       width: 30%;
     }
   }
}

.node-supporter {

  .logo-wrapper {
    position: relative;
    display: block;
    max-width: 300px;
    max-height: 120px;
    overflow: hidden;
    border-bottom: 0;

    .logo-sizer {
      height: 0;
      width: 100%;
      padding-bottom: 33%;
    }

    .field-item {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      img {
        position: relative;
        max-height: 100%;
        max-width: 100%;
        margin: 0;
      }

    }

  }

  h1 {
    font-size: 1.4rem;
    margin: 0;
  }

  a:link,
  a:visited {
    border-bottom: 0;
  }

  p {
    margin-bottom: 0;
  }

}
