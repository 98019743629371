.view-registration-required {

    border-bottom: 4px solid color(rule);

}

.block-user-login--2 {
  .form-item-name {
    .description {
      display: none;
    }
  }
}
